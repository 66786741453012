import { ComponentProps, FC } from 'react'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Grid } from '@mui/material'
import cn from 'classnames'

import { Icon } from '@/libs/common/icon'
import { Typography } from '@/libs/common/typography'
import { IconName } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import styles from './styles.module.scss'

type TProperty = {
  title?: string
  description?: string
  iconName?: ValueOf<typeof IconName>
  withArrowIcon?: boolean
  disabled?: boolean
}

const ListButton: FC<ComponentProps<'div'> & TProperty> = ({
  iconName,
  title,
  description,
  children,
  withArrowIcon = true,
  disabled,
  className,
  ...props
}) => {
  return (
    <div className={cn(styles.container, className, { [styles.disabled]: disabled })} {...props}>
      {iconName && (
        <Grid container className={styles.icon}>
          <Icon name={iconName} />
        </Grid>
      )}
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        wrap="nowrap"
        className={styles.textContent}
        flexGrow={2}
      >
        {!children ? (
          <>
            <Typography
              variant="body2"
              className={cn(styles.textContentItem, styles.textContentItemTitle)}
            >
              {title}
            </Typography>
            <Typography variant="body2" className={styles.textContentItem}>
              {description}
            </Typography>
          </>
        ) : (
          <>{children}</>
        )}
      </Grid>
      <Grid flexGrow={1}></Grid>
      {withArrowIcon && (
        <>
          <Grid flexGrow={1}></Grid>
          <Grid container className={cn(styles.icon, styles.iconLast)}>
            <ChevronRightIcon />
          </Grid>
        </>
      )}
    </div>
  )
}

export { ListButton }
