import { api } from '@/api'

import { TUserFavoritesResponse } from './types'

const BASE_URL = 'favourites'

const getUserFavorites = (networkId: number, isHardUpdate?: boolean) => {
  let url = `${BASE_URL}?networkId=${networkId}`

  if (isHardUpdate) {
    url += '&hard-update=true'
  }

  return api.get<TUserFavoritesResponse>(url)
}

const getUserHistory = (networkId: number) => {
  return api.get<TUserFavoritesResponse>(`history?networkId=${networkId}`)
}

const addUserFavoriteToken = (tokenAddress: string, networkId: number) =>
  api.post(BASE_URL, { tokenAddress, networkId })

const deleteUserFavoriteToken = (tokenAddress: string) => api.delete(`${BASE_URL}/${tokenAddress}`)

const updateFavoritesTokensOrder = (newOrder: { tokenAddress: string; order: number }[]) =>
  api.post(`${BASE_URL}/update-favorites-order`, newOrder)

export {
  getUserFavorites,
  getUserHistory,
  addUserFavoriteToken,
  deleteUserFavoriteToken,
  updateFavoritesTokensOrder,
}
