import { FC } from 'react'

import { ButtonCopy, Table, Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = {
  wallets: {
    private_key: string
  }[]
}

const PrivateKeysTable: FC<TProps> = ({ wallets = [] }) => {
  return (
    <Table
      header={[
        <Typography key="key" variant="body2" className={styles.keyHeader}>
          Private key
        </Typography>,
      ]}
      rows={wallets.map((wallet) => [
        <Typography
          key={`key ${wallet.private_key}`}
          variant="body2"
          className={styles.keyColumn}
          component="div"
        >
          {wallet.private_key}
          <ButtonCopy value={wallet.private_key} className={styles.copy} />
        </Typography>,
      ])}
    />
  )
}

export { PrivateKeysTable }
