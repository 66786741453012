import { useEffect, useState } from 'react'

import { useSniperWebsocket } from '@/hooks/useSniperWebsocket'
import { EWebsocketStatus } from '@/libs/enums/websocket-status.enum'
import { createQueryString } from '@/libs/helper/createQueryString'
import { TSniperSimulationResponse } from '@/libs/types/sniper-simulation-socket-response.type'
import { SniperSockerService } from '@/socket'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentTokenSimulationWebsocket } from '@/store/slices/chain.slice'

const simulationSniperSocket = new SniperSockerService()

const useWatchSimulation = () => {
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)

  const [subscribedToToken, setSubscribedToToken] = useState<string | null>(null)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (
      simulationSniperSocket.getStatus() !== EWebsocketStatus.OPEN ||
      !currentToken ||
      !subscribedToToken ||
      currentToken.token.address.toLowerCase() === subscribedToToken ||
      isAppIdle
    ) {
      return
    }

    simulationSniperSocket.emit(JSON.stringify({ unsubscribe: subscribedToToken }))
    simulationSniperSocket.emit(JSON.stringify({ subscribe: currentToken.token.address }))
    setSubscribedToToken(currentToken.token.address.toLowerCase())
  }, [currentToken, isAppIdle])

  useSniperWebsocket({
    socket: simulationSniperSocket,
    connectionProps: {
      endpoint: 'simulation',
      query: createQueryString({ blockchain: currentChain.indexerChainId }),
      onOpen: () => {
        simulationSniperSocket.emit(JSON.stringify({ subscribe: currentToken?.token.address }))
        setSubscribedToToken(currentToken?.token.address.toLowerCase() || null)
      },
    },
    verifyExistanceBeforeConnect: [currentToken],
    onMessage: (jsonData) => {
      const data = JSON.parse(jsonData) as TSniperSimulationResponse
      if (data.subcribed || data.unsubcribed) {
        return
      }
      dispatch(setCurrentTokenSimulationWebsocket(data))
    },
  })
}

export { useWatchSimulation }
