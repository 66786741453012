import { FC, useState } from 'react'

import { ToggleButtonGroupProps } from '@mui/material'
import cls from 'classnames'

import {
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  ButtonIcon,
  Icon,
  Input,
  Typography,
} from '@/libs/common'
import { IconName, LocalStorageItem } from '@/libs/enums'
import { getBuyAmountOptions } from '@/libs/helper'

import styles from './styles.module.scss'

const defaultValue = getBuyAmountOptions(LocalStorageItem.LITE_BUY_AMOUNT, [
  '0.1',
  '0.25',
  '0.5',
  '1',
  '2',
  '5',
])

type TProperty = ToggleButtonGroupProps & {
  label?: string | React.ReactNode
  separated?: boolean
  groupClassName?: string
  solid?: boolean
}

const BuyAmountGroup: FC<TProperty> = (props) => {
  const [options, setOptions] = useState(defaultValue)
  const [customOptions, setCustomOptions] = useState(defaultValue)
  const [isCustomizeMode, setIsCustomizeMode] = useState(false)

  const handleSubmitSetting = () => {
    if (!validateGroup()) return
    setOptions(customOptions)
    localStorage.setItem(LocalStorageItem.LITE_BUY_AMOUNT, JSON.stringify(customOptions))
    setIsCustomizeMode(false)
  }

  const validateGroup = () => {
    let isValidValue = true
    customOptions.forEach((el) => {
      if (el === '' || el === '0') isValidValue = false
    })
    const isUnique = new Set(customOptions).size === customOptions.length
    return isUnique && isValidValue
  }

  return (
    <div className={styles.container}>
      <div className={styles.controlsWrapper}>
        {isCustomizeMode ? (
          <div className={styles.controls}>
            <ButtonIcon icon={IconName.CHECK_SMALL} type="button" onClick={handleSubmitSetting} />
            <ButtonIcon
              icon={IconName.CLOSE_MIDDLE}
              type="button"
              onClick={() => {
                setCustomOptions(options)
                setIsCustomizeMode(false)
              }}
            />
          </div>
        ) : (
          <ButtonIcon
            className={styles.control}
            icon={IconName.SETTING_2}
            type="button"
            onClick={() => setIsCustomizeMode(true)}
          />
        )}
      </div>
      <ButtonGroupRadio {...props}>
        {options?.map((el, index) => {
          return isCustomizeMode ? (
            <Input
              isNumeric
              placeholder=""
              value={customOptions[index]}
              className={cls(
                cls(styles.customButton, styles.input, {
                  [styles.firstInput]: index === 0,
                  [styles.thirdInput]: index === 2,
                  [styles.forthInput]: index === 3,
                  [styles.lastInput]: index === options.length - 1,
                }),
              )}
              onChange={(e) => {
                const newValue = e.target.value
                setCustomOptions((state) => {
                  const updatedState = [...state]
                  updatedState[index] = newValue
                  return updatedState
                })
              }}
            />
          ) : (
            <ButtonGroupRadioButton
              value={el}
              name="wallet"
              className={cls(styles.customButton, styles.blockButton)}
              key={index}
            >
              <Icon name={IconName.ETH_CURRENCY} />
              <Typography variant="body2" textColor="light-grey">
                {el}
              </Typography>
            </ButtonGroupRadioButton>
          )
        })}
      </ButtonGroupRadio>
    </div>
  )
}

export { BuyAmountGroup }
