import { api } from '@/api'
import { TBuyTemplate } from '@/libs/types/template'

const BASE_URL = 'templates'

const createBuyTemplate = (body: any) => api.post(`${BASE_URL}/buy`, body)

const updateBuyTemplate = (id: string, body: any) => api.patch(`${BASE_URL}/buy/${id}`, body)

const deleteBuyTemplate = (id: string) => api.delete(`${BASE_URL}/buy/${id}`)

const getUserBuyTemplates = () => api.get<TBuyTemplate[]>(`${BASE_URL}/buy`)

const changeUserBuyTemplatesOrder = async (
  updatedTemplateOrder: { _id: string; order: number }[],
) => api.patch<TBuyTemplate[]>(`${BASE_URL}/update-order/buy`, updatedTemplateOrder)

export {
  createBuyTemplate,
  updateBuyTemplate,
  deleteBuyTemplate,
  getUserBuyTemplates,
  changeUserBuyTemplatesOrder,
}
