import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { updateUser } from '@/api/user'
import { AuthCard } from '@/components/auth-card'
import { Button, Input } from '@/libs/common'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { Typography } from '@/libs/common/typography'
import { AppRoute, CredentialsPlaceholders as Placeholder, VerificationStatus } from '@/libs/enums'
import { handleError } from '@/libs/helper/handleError'
import { passwordValidation } from '@/libs/validations/user'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserByIdWithWallets } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const defaultValues = {
  password: '',
  passwordConfirmation: '',
}

const schema = yup
  .object({
    password: passwordValidation,
    passwordConfirmation: yup
      .string()
      .required('Password confirmation is required')
      .oneOf([yup.ref('password')], 'Passwords must match')
      .trim(),
  })
  .required()

const CreateMasterPassword: FC = () => {
  const userData = useAppSelector((state) => state.user.userData)

  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = async (values: typeof defaultValues) => {
    if (!userData) {
      return
    }

    try {
      setIsLoading(true)

      await updateUser(userData._id, { new_master_password: values.password })
      await dispatch(fetchUserByIdWithWallets(userData._id)).unwrap()

      navigate(AppRoute.SETUP_SUCCESS)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <AuthCard
      verificationStatus={VerificationStatus.SETUP_WALLETS}
      className={styles.card}
      isHeaderButtons
    >
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <Typography variant="h1" className={styles.title} align="center">
            Create Master password
          </Typography>
          <TooltipIcon info="This master password ensures that your wallets are encrypted with your password, dramatically increasing the security of the system." />
        </div>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="password"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Input
                isTransparent
                label="Password"
                type="password"
                placeholder={Placeholder.password}
                autoComplete="on"
                error={!!errors.password?.message}
                disabled={isLoading}
                {...field}
              />
            )}
          />

          <Controller
            name="passwordConfirmation"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Input
                isTransparent
                label="Repeat password"
                type="password"
                placeholder={Placeholder.password}
                autoComplete="on"
                error={!!errors.passwordConfirmation?.message}
                disabled={isLoading}
                {...field}
              />
            )}
          />

          <Button type="submit" isLoading={isLoading}>
            Create
          </Button>
        </form>
      </div>
    </AuthCard>
  )
}

export { CreateMasterPassword }
