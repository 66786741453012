import { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Accordion, AccordionListElement, Typography } from '@/libs/common'
import { DragAndDropList } from '@/libs/common/drag-and-drop-list'
import { AppMode, AppRoute, TemplateGroup } from '@/libs/enums'
import { TBuyTemplate } from '@/libs/types/template'
import { useAppDispatch, useAppSelector } from '@/store'
import {
  setCurrentSelectedTemplate,
  setUserBuyTemplates,
  updateBuyTemplatesOrder,
} from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const TemplatesAccordion: FC = () => {
  const dispatch = useAppDispatch()
  const userTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const buyTemplates = useMemo(
    () => userTemplates?.filter((item) => item.network.blockchain === currentChain.indexerChainId),
    [userTemplates, currentChain],
  )

  const navigate = useNavigate()

  const handleOpenModal = () => {
    navigate(
      `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.TEMPLATE_MANAGEMENT}`,
    )
  }

  const openTemplateSettings = (templateId: string) => {
    navigate(
      `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.MANUAL_BUY_TEMPLATE}/${templateId}`,
    )
  }

  const handleChangeBuyTemplatesOrder = () => {
    if (!buyTemplates) return

    const newTemplatesOrder = buyTemplates?.map(({ _id }, order) => ({
      _id,
      order,
    }))

    dispatch(updateBuyTemplatesOrder(newTemplatesOrder))
  }

  const [isSomeElementDragging, setIsSomeElementDragging] = useState(false)

  const renderItem = useCallback(
    (template: TBuyTemplate, isDragging: boolean) => (
      <AccordionListElement
        disableHover={isSomeElementDragging && !isDragging}
        onClick={() => {
          dispatch(
            setCurrentSelectedTemplate({
              group: TemplateGroup.BUY,
              id: template._id,
            }),
          )
          openTemplateSettings(template._id)
        }}
        className={styles.templateItem}
        key={template._id}
        enableBurger
      >
        <Typography variant="body2" className={styles.content}>
          {template.name}
        </Typography>
      </AccordionListElement>
    ),
    [isSomeElementDragging],
  )

  return (
    <Accordion
      title="Templates"
      enableSettings
      onSettingClick={handleOpenModal}
      contentWithScroll="templates"
    >
      <div className={styles.group}>
        <Typography variant="body2" textColor="light-grey">
          Manual Buy
        </Typography>
        {buyTemplates?.length ? (
          <DragAndDropList
            data={buyTemplates}
            propOfItemForKey="_id"
            setData={(newOrder) => {
              dispatch(setUserBuyTemplates(newOrder))
            }}
            renderItem={renderItem}
            onDragStateChange={(state) => {
              setIsSomeElementDragging(state)
            }}
            onOrderChanged={handleChangeBuyTemplatesOrder}
          />
        ) : (
          <Typography variant="body1" component="span" textColor="grey" align="center">
            No templates
          </Typography>
        )}
      </div>
    </Accordion>
  )
}

export { TemplatesAccordion }
