import { useSniperWebsocket } from '@/hooks/useSniperWebsocket'
import { createQueryString } from '@/libs/helper/createQueryString'
import { TSniperBasicResponse } from '@/libs/types/sniper-socket-responses.type'
import { SniperSockerService } from '@/socket'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentChainBasicInfo } from '@/store/slices/chain.slice'

const basicSniperSocket = new SniperSockerService()

const useWatchChainInfo = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const dispatch = useAppDispatch()

  useSniperWebsocket({
    socket: basicSniperSocket,
    connectionProps: {
      endpoint: 'basic',
      query: createQueryString({ blockchain: currentChain.indexerChainId }),
    },
    onMessage: (jsonData) => {
      const data = JSON.parse(jsonData) as TSniperBasicResponse
      dispatch(setCurrentChainBasicInfo(data))
    },
  })
}

export { useWatchChainInfo }
