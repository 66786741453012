import { useEffect, useState } from 'react'

import { useSniperWebsocket } from '@/hooks/useSniperWebsocket'
import { EWebsocketStatus } from '@/libs/enums/websocket-status.enum'
import { isEquals } from '@/libs/helper'
import { createQueryString } from '@/libs/helper/createQueryString'
import { TSniperSocketBalanceResponse } from '@/libs/types/sniper-socket-responses.type'
import { SniperSockerService } from '@/socket'
import { useAppDispatch, useAppSelector } from '@/store'
import { updateUserWallet } from '@/store/slices/user.slice'

const balancesSniperSocket = new SniperSockerService(import.meta.env.VITE_SNIPER_INDEXER_SOCKET_URL)

const useWatchBalances = () => {
  const dispatch = useAppDispatch()

  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)

  const [walletsAddresses, setWalletsAddresses] = useState<string[]>([])

  useEffect(() => {
    if (
      balancesSniperSocket.getStatus() !== EWebsocketStatus.OPEN ||
      !userWallets ||
      !walletsAddresses ||
      isAppIdle
    ) {
      return
    }

    const updatedAddresses = userWallets.map((wallet) => wallet.public_key)

    if (!isEquals(walletsAddresses, updatedAddresses)) {
      balancesSniperSocket.emit(JSON.stringify({ unsubscribe: walletsAddresses }))
      balancesSniperSocket.emit(JSON.stringify({ subscribe: updatedAddresses }))

      setWalletsAddresses(updatedAddresses)
    }
  }, [userWallets, isAppIdle])

  useSniperWebsocket({
    socket: balancesSniperSocket,
    connectionProps: {
      endpoint: 'balances',
      query: createQueryString({ blockchain: currentChain.indexerChainId }),
      onOpen: () => {
        if (!userWallets) return
        const wallets = userWallets.map((wallet) => wallet.public_key)
        balancesSniperSocket.emit(JSON.stringify({ subscribe: wallets }))
        setWalletsAddresses(wallets)
      },
    },
    verifyExistanceBeforeConnect: [userWallets],
    onMessage: (jsonData) => {
      const data = JSON.parse(jsonData) as TSniperSocketBalanceResponse
      if (data.subcribed || data.unsubcribed) {
        return
      }
      dispatch(updateUserWallet(data))
    },
  })
}

export { useWatchBalances }
