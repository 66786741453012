import { useMemo } from 'react'

import { api } from '@/api'
import {
  ErrorCallback,
  HistoryCallback,
  LibrarySymbolInfo,
  OnReadyCallback,
  PeriodParams,
  ResolutionString,
  ResolveCallback,
  SubscribeBarsCallback,
} from '@/charting_library/charting_library'
import { getErrorMessage } from '@/libs/helper'
import { ITokenInfo } from '@/libs/types/token-info-response.type'
import { definedService } from '@/socket/libs/defined'

import { MAX_COUNT_BACK, RESOLUTION_MAP } from './constants'
import { calculateResolutionCoef } from './helpers'

const configurationData = {
  supported_resolutions: [
    '1S',
    '15S',
    '1',
    '5',
    '15',
    '60',
    '240',
    '720',
    '1D',
    '3D',
    '7D',
  ] as ResolutionString[],
  has_seconds: true,
  has_intraday: true,
  has_daily: true,
  has_weekly_and_monthly: true,
  seconds_multipliers: ['1', '15'],
}

const useDatafeed = (
  token: ITokenInfo | null,
  isPriceSelected: boolean,
  isCurrencyPriceSelected: boolean,
) => {
  let previousResolution: string
  let count = 0
  let previousFrom = 0
  let previousCountBack = 0
  let isLowVolumeToken = false
  let bars = [] as any[]

  return useMemo(() => {
    return {
      onReady: (callback: OnReadyCallback) => {
        setTimeout(() => callback(configurationData))
      },

      resolveSymbol: async (symbol: string, onSymbolResolvedCallback: ResolveCallback) => {
        if (!token) return

        // const secondaryToken =
        //   token.token.address === token.pair.token0Data.address
        //     ? token.pair.token1Data
        //     : token.pair.token0Data

        const symbolInfo: LibrarySymbolInfo = {
          ticker: symbol,
          name: symbol.split('/')[0],
          description: '',
          type: '',
          session: '24x7',
          timezone: 'Etc/UTC',
          exchange: '',
          listed_exchange: '',
          format: 'price',
          minmov: 1,
          pricescale: 10 ** 16,
          has_intraday: configurationData.has_intraday,
          has_weekly_and_monthly: configurationData.has_weekly_and_monthly,
          has_daily: configurationData.has_daily,
          intraday_multipliers: ['1', '5', '15', '60', '240', '720'] as ResolutionString[],
          daily_multipliers: ['1', '7'],
          has_seconds: configurationData.has_seconds,
          supported_resolutions: configurationData.supported_resolutions as ResolutionString[],
          volume_precision: 2,
          data_status: 'streaming',
          seconds_multipliers: configurationData.seconds_multipliers as ResolutionString[],
          // has_empty_bars: true,
        }

        setTimeout(() => onSymbolResolvedCallback(symbolInfo))
      },

      getBars: async (
        _: LibrarySymbolInfo,
        resolution: ResolutionString,
        periodParams: PeriodParams,
        onHistoryCallback: HistoryCallback,
        onErrorCallback: ErrorCallback,
      ) => {
        const { to, countBack } = periodParams

        const isValidCount = countBack - previousCountBack > 0

        if (previousResolution !== resolution || !isValidCount) {
          isLowVolumeToken = false
          count = 0
          previousFrom = 0
          previousCountBack = 0
          bars = []
        }

        previousResolution = resolution
        const coef = calculateResolutionCoef(resolution)

        const restCountBack = previousCountBack ? countBack - previousCountBack : countBack
        const currentCountBack = restCountBack > MAX_COUNT_BACK ? MAX_COUNT_BACK : restCountBack
        const currentTo = bars[0] ? bars[0].time / 1000 : previousFrom ? previousFrom : to
        const currentFrom = currentTo - currentCountBack * coef

        const urlParameters: Record<string, string | number | undefined> = {
          symbol: token?.pair.id,
          from: currentFrom,
          to: currentTo,
          resolution,
          quoteToken: token?.quoteToken,
          countBack: currentCountBack,
          removeEmptyBars: 1,
        }

        const query = Object.keys(urlParameters)
          .map((name) => `${name}=${urlParameters[name]}`)
          .join('&')

        if (
          (resolution === '1S' ||
            resolution === '15S' ||
            resolution === '1' ||
            resolution === '15') &&
          count > 2
        ) {
          onErrorCallback('no data')

          return
        }

        try {
          const { data: barsData } = await api.get(`tokens/get-bars?${query}`)

          previousFrom = currentFrom
          previousCountBack += currentCountBack

          bars = [...barsData, ...bars].sort((a, b) => a.time - b.time)

          if (!barsData.length) {
            if (
              resolution === '1S' ||
              resolution === '15S' ||
              resolution === '1' ||
              resolution === '15'
            ) {
              count += 1
            }

            onHistoryCallback(isLowVolumeToken ? [] : bars, {
              noData: true,
            })

            return
          } else {
            count = 0
            onHistoryCallback(bars)
          }
        } catch (error) {
          const err = getErrorMessage(error)
          onErrorCallback(err)
        } finally {
          previousResolution = resolution
        }
      },

      subscribeBars: async (
        _: LibrarySymbolInfo,
        resolution: ResolutionString,
        onRealtimeCallback: SubscribeBarsCallback,
        _listenerGuid: string,
        onResetCacheNeededCallback: () => void,
      ) => {
        if (!token) return
        onResetCacheNeededCallback()

        definedService.subscribeToken(token?.pair.id, (data) => {
          const ohlcvt = data[RESOLUTION_MAP[resolution as keyof typeof RESOLUTION_MAP]].usd

          const lastBar = {
            time: ohlcvt.t * 1000,
            high: ohlcvt.h,
            low: ohlcvt.l,
            open: ohlcvt.o,
            close: ohlcvt.c,
            volume: ohlcvt.v,
          }

          onRealtimeCallback(lastBar)
        })
      },

      unsubscribeBars: () => {
        definedService.unsubscribeToken()
      },
    }
  }, [token, isPriceSelected, isCurrencyPriceSelected])
}

export { useDatafeed }
