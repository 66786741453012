import { FC } from 'react'

import { DepositTab } from '@/components/deposit-tab'
import { useWalletFromParams } from '@/hooks/useWalletFromParams'
import { BackButton, Tabs, Typography } from '@/libs/common'
import { TabName } from '@/libs/enums'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useAppSelector } from '@/store'

import { HoldingsTab } from './libs/components/holdings-tab'
import { TransferTab } from './libs/components/transfer-tab'

const WalletDetails: FC = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const wallet = useWalletFromParams()

  return (
    <div>
      <BackButton isAbsolute />
      <Typography variant="h1" align="center">
        {wallet?.name}
      </Typography>
      <Typography variant="body2" align="center">
        {formatTokenPrice(`${wallet?.balanceFormatted || 0}`).formatted} {currentChain.description}{' '}
        (~$
        {(
          +(wallet?.balanceFormatted || 0) * (currentChain.nativeTokenPriceInUsd || 0)
        ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
        )
      </Typography>
      <Tabs
        name={TabName.Wallet_DETAILS}
        tabs={[
          { label: 'Holdings', content: <HoldingsTab wallet={wallet} /> },
          { label: 'Deposit', content: <DepositTab wallet={wallet} /> },
          { label: 'Transfer', content: <TransferTab wallet={wallet} /> },
        ]}
      />
    </div>
  )
}

export { WalletDetails }
