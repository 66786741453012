import { FC, useEffect, useMemo, useState } from 'react'

import cls from 'classnames'

import { getDetailedPairStats } from '@/api/tokens'
import { TDetailedPairStats } from '@/api/tokens/types'
import { ValuesCollation } from '@/components/values-collation'
import { ButtonGroupRadio, ButtonGroupRadioButton, Typography } from '@/libs/common'
import { TokenDataItem } from '@/libs/common/token-data-item'
import { formatNumber } from '@/libs/helper'
import { TSelectOption } from '@/libs/types/select-option.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const options: TSelectOption<string>[] = [
  {
    value: 'stats_min5',
    label: '5M',
  },
  {
    value: 'stats_hour1',
    label: '1H',
  },
  {
    value: 'stats_hour4',
    label: '4H',
  },
  {
    value: 'stats_day1',
    label: '24H',
  },
]

type TProperty = {
  variant?: 'pro' | 'lite'
}

const PairStats: FC<TProperty> = ({ variant = 'lite' }) => {
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const chain = useAppSelector((state) => state.chain.currentChain)
  const [currentOption, setCurrentOption] = useState(options[0].value)
  const [pairStats, setPairStats] = useState<TDetailedPairStats | null>(null)
  const selectedStat = useMemo(
    () => (pairStats ? pairStats[currentOption as keyof TDetailedPairStats] : null),
    [pairStats, currentOption],
  )
  const test = async () => {
    if (!currentToken) return
    const { data } = await getDetailedPairStats(currentToken.pair.address, chain.id)
    setPairStats(data.getDetailedPairStats)
  }
  useEffect(() => {
    test()
  }, [currentToken, chain])

  return (
    <div className={cls(styles.container, variant === 'lite' && styles.lite)}>
      <ButtonGroupRadio
        exclusive
        className={styles.radioGroup}
        groupClassName={styles.radioGroupContent}
        value={currentOption}
        onChange={(_, value) => {
          setCurrentOption(value)
        }}
      >
        {options?.map((el, index) => {
          const change = pairStats
            ? pairStats[el.value as keyof TDetailedPairStats].statsUsd.close.change * 100
            : 0

          return (
            <ButtonGroupRadioButton
              value={el.value}
              name="wallet"
              className={cls(styles.customButton, styles.blockButton)}
              key={index}
            >
              <Typography variant="body2" textColor="light-grey">
                {el.label}
              </Typography>
              <Typography
                variant="body2"
                className={cls(change > 0 ? styles.greenText : change < 0 ? styles.redText : '')}
              >
                {formatNumber(change).formatted}%
              </Typography>
            </ButtonGroupRadioButton>
          )
        })}
      </ButtonGroupRadio>
      <div className={styles.content}>
        <div className={styles.row}>
          <TokenDataItem
            title="TXNS"
            value={
              formatNumber(selectedStat?.statsNonCurrency.transactions.currentValue || 0).formatted
            }
            className={styles.summary}
          />
          <div className={styles.details}>
            <ValuesCollation
              firstElement={{
                title: 'Buys',
                value: selectedStat?.statsNonCurrency.buys.currentValue,
              }}
              secondElement={{
                title: 'Sells',
                value: selectedStat?.statsNonCurrency.sells.currentValue,
              }}
            />
          </div>
        </div>
        <div className={styles.row}>
          <TokenDataItem
            title="Volume"
            value={`$${formatNumber(selectedStat?.statsUsd.volume.currentValue || 0).raw}`}
            className={styles.summary}
          />
          <div className={styles.details}>
            <ValuesCollation
              firstElement={{
                title: 'Buy vol',
                value: selectedStat?.statsUsd.buyVolume.currentValue,
              }}
              secondElement={{
                title: 'Sell vol',
                value: selectedStat?.statsUsd.sellVolume.currentValue,
              }}
            />
          </div>
        </div>
        <div className={styles.row}>
          <TokenDataItem
            title="Makers"
            value={formatNumber(selectedStat?.statsNonCurrency.traders.currentValue || 0).formatted}
            className={styles.summary}
          />
          <div className={styles.details}>
            <ValuesCollation
              firstElement={{
                title: 'Buyers',
                value: selectedStat?.statsNonCurrency.buyers.currentValue,
              }}
              secondElement={{
                title: 'Sellers',
                value: selectedStat?.statsNonCurrency.sellers.currentValue,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export { PairStats }
