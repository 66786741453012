import { FC, MutableRefObject, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import cn from 'classnames'

import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import {
  Icon,
  InputSearch,
  Popover,
  SignOutButton,
  TOnChangeVisible,
  Typography,
} from '@/libs/common'
import { Logo } from '@/libs/common/logo'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
// import { PlanName } from '@/libs/enums'
// import { TPlan } from '@/libs/types'
import { useAppSelector } from '@/store'

import { ChainStatusInfo } from './libs/components/chain-status-info'
import { InfoItem } from './libs/components/info-item'
import { SwitchVariant } from './libs/components/switch-variant'
import { UserBlock } from './libs/components/user-block'
import styles from './styles.module.scss'

const Header: FC = () => {
  const { mode } = useParams()
  // const [searchParams] = useSearchParams()
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userData = useAppSelector((state) => state.user.userData)
  const mainWallet = useAppSelector((state) => state.user.mainWallet)
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const [popoverState, setPopoverState] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })

  const { isBottomTabsVisible, width } = useWindowDimensions()
  const isMobileSidebar = width < 1200

  const renderNavigation = () => (
    <nav className={styles.nav}>
      {/* <Link className={styles.navLink} to="/wallets">
        <div className={cn(styles.navLinkIcon, styles.navLinkIconWallet)}>
          <Icon name={IconName.WALLET} />
        </div>
        Wallets
      </Link>

      <Link className={styles.navLink} to="/settings">
        <div className={cn(styles.navLinkIcon, styles.navLinkIconSettings)}>
          <Icon name={IconName.SETTING} />
        </div>
        Settings
      </Link> */}

      <div className={styles.navLink}>
        <SignOutButton variant="onboarding" buttonClassName={styles.signOutButton} />
      </div>
    </nav>
  )

  return (
    <>
      <Grid
        ref={ref}
        container
        component="header"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        className={cn(styles.header, styles[currentChain.chainName])}
      >
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="space-between"
          className={styles.container}
        >
          <div className={cn(styles.logoWrapper, mode ? styles[mode] : '')}>
            <Logo size="medium" hideText={isBottomTabsVisible && mode !== 'lite'} />

            <div className={styles.search}>
              <SwitchVariant />

              <div className={styles.searchBlock}>
                <InputSearch placeholder="Enter token name or address" />
              </div>

              <ChainStatusInfo />
            </div>

            <div
              className={styles.userData}
              onClick={(e) => {
                setPopoverState((prevValue) => ({
                  visible: !prevValue.visible,
                  anchorE: e.currentTarget,
                }))
              }}
            >
              <div>
                <UserBlock userName={userData?.name} />

                <InfoItem
                  title="Balance:"
                  value={
                    <Typography
                      variant="caption"
                      textColor="light-grey"
                      className={styles.balance}
                      title={
                        mainWallet?.balanceFormatted &&
                        formatNumber(mainWallet?.balanceFormatted).formatted.toString()
                      }
                    >
                      <Icon name={IconName.ETH_CURRENCY} />{' '}
                      {mainWallet?.balanceFormatted
                        ? formatNumber(mainWallet?.balanceFormatted).formatted
                        : 0}
                    </Typography>
                  }
                />
              </div>

              <div className={styles.switch}>
                <Icon name={IconName.SWITCH} />
              </div>
            </div>

            {!isMobileSidebar && (
              <Popover
                className={styles.popover}
                popoverState={popoverState}
                onChangeVisible={setPopoverState}
              >
                {renderNavigation()}
              </Popover>
            )}
          </div>
        </Grid>

        {isMobileSidebar && (
          <div
            className={cn(styles.mobileSidebar, {
              [styles.visible]: popoverState.visible,
            })}
          >
            <Grid rowGap={2} display="flex" flexDirection="column">
              <ChainStatusInfo />
              <SwitchVariant />
              {renderNavigation()}
            </Grid>
          </div>
        )}
      </Grid>

      {isMobileSidebar && (
        <div
          className={cn(styles.overlay, {
            [styles.visible]: popoverState.visible,
          })}
          onClick={(e) => {
            e.stopPropagation()
            setPopoverState({
              visible: false,
              anchorE: undefined,
            })
          }}
        />
      )}
    </>
  )
}

export { Header }
