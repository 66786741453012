import { FC } from 'react'

import { Stack } from '@mui/material'

import { Button, Input, Typography } from '@/libs/common'

import styles from './styles.module.scss'

const MasterPassword: FC = () => {
  return (
    <Stack spacing={3} useFlexGap>
      <Typography variant="h1" align="center">
        Enter master password
      </Typography>
      <Input
        isTransparent
        label="Master password"
        placeholder="Enter master password"
        className={styles.input}
      />
      <Button disabled>Submit</Button>
    </Stack>
  )
}

export { MasterPassword }
