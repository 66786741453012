import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getMyCommissionTier } from '@/api/affiliate'
import { TCommissionTier } from '@/api/affiliate/types'
import { TUserFavorite } from '@/api/favorites/types'
import { getProfitLossDetails } from '@/api/profit-loss'
import { TProfitWallet } from '@/api/profit-loss/types'
import { changeUserBuyTemplatesOrder, getUserBuyTemplates } from '@/api/templates'
import { getUserById } from '@/api/user'
import { deleteWallet, getUserWalletsFormatted } from '@/api/wallet'
import { formatBalanceForHoldings, getHoldingsSettings } from '@/libs/helper'
import { THoldingsSettings, TUser } from '@/libs/types'
import { TSniperHoldings } from '@/libs/types/sniper-holdings-socket-response.type'
import { TSniperSocketBalance } from '@/libs/types/sniper-socket-responses.type'
import { TBuyTemplate } from '@/libs/types/template'
import {
  TUserCurrentSelectedTemplate,
  TUserWallet,
  TUserWalletUnconfirmed,
} from '@/libs/types/user.type'
import { TRootState } from '@/store'
import { createAsyncThunkWithControll } from '@/utils/requestsController'

type TInitialState = {
  userData: TUser | null
  userWallets: TUserWallet[] | null
  userSkipDeleteUnconfirmedWallets: boolean
  userWalletsUnconfirmed: TUserWalletUnconfirmed[] | null
  userWalletsWithSelectedToken: TProfitWallet[] | null
  userHoldingsWebsocket: TSniperHoldings | null
  userHoldingsLoading: boolean
  userHoldingsSettings: THoldingsSettings
  mainWallet: TUserWallet | null
  userTemplates: {
    buyTemplates: TBuyTemplate[] | null
  }
  userCurrentSelectedTemplate: TUserCurrentSelectedTemplate
  userFavorites: TUserFavorite[] | null
  userCommissionTier: TCommissionTier | null
}

const initialState: TInitialState = {
  userData: null,
  userWallets: null,
  userSkipDeleteUnconfirmedWallets: false,
  userWalletsUnconfirmed: null,
  userWalletsWithSelectedToken: null,
  userHoldingsWebsocket: null,
  userHoldingsLoading: true,
  userHoldingsSettings: getHoldingsSettings(),
  mainWallet: null,
  userTemplates: {
    buyTemplates: null,
  },
  userCurrentSelectedTemplate: {
    group: null,
    id: null,
  },
  userFavorites: null,
  userCommissionTier: null,
}

const fetchUserBuyTemplates = createAsyncThunk('user/fetchUserBuyTemplates', async () => {
  const { data } = await getUserBuyTemplates()
  return data.sort((a, b) => a.order - b.order)
})

const {
  thunk: fetchUserWalletsWithTokens,
  thunkForce: fetchUserWalletsWithTokensForce,
  applyExtraReducers: applyExtraReducersForUserWalletsWithTokens,
} = createAsyncThunkWithControll(
  'user/fetchUserWalletsWithTokens',
  async (tokenAddress: string) => {
    const { data } = await getProfitLossDetails(tokenAddress)
    return data
  },
)

const {
  thunk: fetchUserCommissionTier,
  applyExtraReducers: applyExtraReducersForUserCommissionTier,
} = createAsyncThunkWithControll('user/fetchUserCommissionTier', async () => {
  const { data } = await getMyCommissionTier()
  return data
})

const fetchUserByIdWithWallets = createAsyncThunk(
  'user/fetchUserByIdWithWallets',
  async (userId: string) => {
    const [{ data: userData }, userWallets] = await Promise.all([
      getUserById(userId),
      getUserWalletsFormatted(userId),
    ])
    return { userData, userWallets }
  },
)

const fetchDeleteUserUnconfirmedWallets = createAsyncThunk(
  'user/fetchDeleteUserUnconfirmedWallets',
  async (_, { getState }) => {
    const { userData, userWalletsUnconfirmed, userSkipDeleteUnconfirmedWallets } = (
      getState() as TRootState
    ).user

    if (userData && userWalletsUnconfirmed && !userSkipDeleteUnconfirmedWallets) {
      await Promise.all(
        userWalletsUnconfirmed.map(({ public_key }) => deleteWallet(userData?._id, public_key)),
      )
    }
  },
)

const {
  thunk: fetchUserWallets,
  thunkForce: fetchUserWalletsForce,
  applyExtraReducers: applyExtraReducersForUserWallets,
} = createAsyncThunkWithControll(
  'user/fetchUserWallets',
  async (userId: string, { dispatch, getState }) => {
    const idToUse = userId || (getState() as TRootState).user.userData?._id
    if (!idToUse) return null
    await dispatch(fetchDeleteUserUnconfirmedWallets()).unwrap()

    const userWallets = await getUserWalletsFormatted(idToUse)
    return userWallets
  },
)

const updateBuyTemplatesOrder = createAsyncThunk(
  'user/updateBuyTemplatesOrder',
  async (updatedTemplateOrder: { _id: string; order: number }[]) => {
    const { data } = await changeUserBuyTemplatesOrder(updatedTemplateOrder)
    return data.sort((a, b) => a.order - b.order)
  },
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<TUser>) => {
      state.userData = payload
    },
    setUserWallets: (state, { payload }: PayloadAction<TUserWallet[]>) => {
      state.userWallets = payload
    },
    setUserWalletsUnconfirmed: (state, { payload }: PayloadAction<TUserWalletUnconfirmed[]>) => {
      state.userWalletsUnconfirmed = payload.map(({ public_key }) => {
        return { public_key }
      })
      state.userSkipDeleteUnconfirmedWallets = true
    },
    updateUserWallet: (state, { payload }: PayloadAction<TSniperSocketBalance[]>) => {
      if (!state.userWallets) return

      const updatedData: TUserWallet[] = []

      payload.forEach((balance) => {
        const wallet = state.userWallets?.find(
          (el) => el.public_key === balance.a && +(el.balanceFormatted || 0) !== +balance.b,
        )
        if (wallet) updatedData.push({ ...wallet, balanceFormatted: balance.b })
      })

      if (!updatedData.length) return

      state.userWallets = state.userWallets.map((el) => {
        const updatedWallet = updatedData.find((updatedEl) => updatedEl.id === el.id)
        return updatedWallet ? updatedWallet : el
      })
    },
    setUserBuyTemplates: (state, { payload }: PayloadAction<TBuyTemplate[]>) => {
      state.userTemplates.buyTemplates = payload
    },
    setUserHoldingsWebsocket: (state, { payload }: PayloadAction<TSniperHoldings>) => {
      const parsed = JSON.parse(JSON.stringify(payload)) as TSniperHoldings
      payload.v.t = formatBalanceForHoldings(parsed.v.t)
      payload.s = formatBalanceForHoldings(parsed.s)
      state.userHoldingsWebsocket = payload
      if (state.userHoldingsLoading) state.userHoldingsLoading = false
    },
    setUserHoldingsSettings: (state, { payload }: PayloadAction<THoldingsSettings>) => {
      state.userHoldingsSettings = payload
    },
    removeUser: (state) => {
      state.userData = null
      state.userWallets = null
    },
    removeUserWalletsWithToken: (state) => {
      state.userWalletsWithSelectedToken = null
    },
    clearUserWalletsUnconfirmed: (state) => {
      state.userWalletsUnconfirmed = null
    },
    toggleSkipCleanUnconfirmedWallets: (state, { payload }: PayloadAction<boolean>) => {
      state.userSkipDeleteUnconfirmedWallets = payload
    },
    setCurrentSelectedTemplate: (
      state,
      { payload }: PayloadAction<TUserCurrentSelectedTemplate>,
    ) => {
      state.userCurrentSelectedTemplate = payload
    },
    clearCurrentSelectedTemplate: (state) => {
      state.userCurrentSelectedTemplate = { id: null, group: null }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserByIdWithWallets.fulfilled, (state, { payload }) => {
      const main = payload.userWallets.find((wallet: TUserWallet) => wallet.isDefault)
      if (main) state.mainWallet = main
      state.userData = payload.userData
      state.userWallets = payload.userWallets
    })

    builder.addCase(fetchDeleteUserUnconfirmedWallets.fulfilled, (state) => {
      state.userWalletsUnconfirmed = null
      state.userSkipDeleteUnconfirmedWallets = true
    })

    applyExtraReducersForUserWalletsWithTokens(builder, {
      onFulfilled: (state, { payload }) => {
        const userWallets = state.userWallets
        const userWalletsWithSelectedToken: TProfitWallet[] = []

        payload.data.forEach((item: any) => {
          if (!userWallets || !item.currentTokens) return

          const wallet = userWallets.find(
            (wallet) => wallet.public_key === item.publicWallet.public_key,
          )

          if (wallet && +item.currentTokens !== 0) {
            userWalletsWithSelectedToken.push(item)
          }
        })
        state.userWalletsWithSelectedToken = userWalletsWithSelectedToken?.length
          ? userWalletsWithSelectedToken
          : null
      },
    })

    applyExtraReducersForUserWallets(builder, {
      onFulfilled: (state, { payload }) => {
        const main = payload.find((wallet: TUserWallet) => wallet.isDefault)
        if (main) state.mainWallet = main
        state.userWallets = payload
      },
    })

    applyExtraReducersForUserCommissionTier(builder, {
      onFulfilled: (state, { payload }) => {
        state.userCommissionTier = payload
      },
    })

    builder.addCase(fetchUserBuyTemplates.fulfilled, (state, { payload }) => {
      state.userTemplates.buyTemplates = payload
    })

    builder.addCase(updateBuyTemplatesOrder.fulfilled, (state, { payload }) => {
      state.userTemplates.buyTemplates = payload
    })
  },
})

const {
  setUser,
  setUserWallets,
  setUserWalletsUnconfirmed,
  setUserHoldingsWebsocket,
  removeUser,
  updateUserWallet,
  removeUserWalletsWithToken,
  clearUserWalletsUnconfirmed,
  toggleSkipCleanUnconfirmedWallets,
  setCurrentSelectedTemplate,
  setUserBuyTemplates,
  clearCurrentSelectedTemplate,
  setUserHoldingsSettings,
} = userSlice.actions

export {
  userSlice,

  // Reducers
  setUser,
  setUserWallets,
  setUserWalletsUnconfirmed,
  removeUser,
  removeUserWalletsWithToken,
  clearUserWalletsUnconfirmed,
  toggleSkipCleanUnconfirmedWallets,
  setCurrentSelectedTemplate,
  setUserBuyTemplates,
  clearCurrentSelectedTemplate,
  setUserHoldingsWebsocket,
  setUserHoldingsSettings,
  updateUserWallet,

  // Thunks
  fetchUserByIdWithWallets,
  fetchUserWallets,
  fetchUserWalletsForce,
  fetchUserWalletsWithTokens,
  fetchUserWalletsWithTokensForce,
  fetchUserBuyTemplates,
  fetchDeleteUserUnconfirmedWallets,
  updateBuyTemplatesOrder,
  fetchUserCommissionTier,
}
