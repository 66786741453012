import { FC } from 'react'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'

import { RowLoadingPlaceholder } from '@/components/row-loading-placeholder'
import { RefreshButton } from '@/libs/common'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserFavorites } from '@/store/slices/favorites-tokens.slice'

import { Row } from './libs/row/row'
import styles from './styles.module.scss'

// type TProperty = {
//   height: number
// }

const FavoritesTable: FC = () => {
  const dispatch = useAppDispatch()
  const networkId = useAppSelector((state) => state.chain.currentChain.id)
  const favorites = useAppSelector((state) => state.favoritesTokens.userFavorites)
  const isLoading = useAppSelector((state) => state.favoritesTokens.isLoading)

  const handleRefresh = () => {
    dispatch(fetchUserFavorites({ networkId, isHardUpdate: true }))
  }

  return (
    <Paper className={styles.root}>
      <TableContainer
        className={cn(styles.container, styles.containerSmall)}
        id="holdings-table"
        // style={{ maxHeight: `${height}px` }}
      >
        <Table stickyHeader aria-label="sticky table" classes={styles}>
          <TableHead>
            <TableRow className={styles.headRow}>
              <TableCell className={styles.tableHeadCell}>
                <div className={styles.refreshWrapper}>
                  Token <RefreshButton onClick={handleRefresh} isLoading={isLoading} />
                </div>
              </TableCell>
              <TableCell className={styles.tableHeadCell}>Price USD</TableCell>
              <TableCell className={styles.tableHeadCell}>Volume</TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.changesColumn)}>24H</TableCell>
              <TableCell className={styles.tableHeadCell}>Liquidity</TableCell>
              <TableCell className={styles.tableHeadCell}>Mcap</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {favorites && favorites.map((row) => <Row row={row} key={row.address} />)}
            {!favorites && isLoading && <RowLoadingPlaceholder styleValiant="lite" />}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export { FavoritesTable }
