import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import cn from 'classnames'

import { ButtonIcon } from '@/libs/common/button-icon'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProperty = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  children: React.ReactNode
  className?: string
  enableBurger?: boolean
  disableHover?: boolean
}

const AccordionListElement: FC<TProperty> = ({
  children,
  enableBurger = true,
  disableHover,
  className,
  ...props
}) => {
  return (
    <div
      className={cn(styles.element, { [styles.disableHover]: disableHover }, className)}
      {...props}
    >
      {enableBurger && <ButtonIcon icon={IconName.BURGER} className={styles.burger} />}
      {children}
    </div>
  )
}

export { AccordionListElement }
