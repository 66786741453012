import { FC, useState } from 'react'

import cls from 'classnames'

import { TransactionsTable } from '@/components/transactions-table'
import { ButtonGroupRadio, ButtonGroupRadioButton } from '@/libs/common'

import styles from './styles.module.scss'

const types = [
  {
    value: 'all',
    label: 'All transactions',
  },
  {
    value: 'own',
    label: 'My transactions',
  },
]

const TransactionsContainer: FC = () => {
  const [type, setType] = useState(types[0].value)

  return (
    <div>
      <div>
        <ButtonGroupRadio
          className={styles.group}
          value={type}
          onChange={(_: React.BaseSyntheticEvent, newValue: string) => setType(newValue)}
          exclusive
        >
          {types.map(({ value, label }) => (
            <ButtonGroupRadioButton
              key={value}
              value={value}
              className={cls(styles.button, styles.bigSize, {
                [styles.active]: type === value,
              })}
            >
              {label}
            </ButtonGroupRadioButton>
          ))}
        </ButtonGroupRadio>
      </div>
      <TransactionsTable
        size="small"
        styleValiant="lite"
        ownTransactions={type === types[1].value}
      />
    </div>
  )
}

export { TransactionsContainer }
