import { FC } from 'react'
import { useLocation } from 'react-router-dom'

import { Grid } from '@mui/material'

import { Typography } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { useAppSelector } from '@/store'

import { WalletItem } from './libs/components/wallet-item'
import styles from './styles.module.scss'

const SelectWalletToDeposit: FC = () => {
  const wallets = useAppSelector((state) => state.user.userWallets)
  const location = useLocation()
  const type = location.pathname.includes(AppRoute.TRANSFER_WALLETS) ? 'transfer' : 'deposit'

  return (
    <div>
      <Typography variant="h1" className={styles.title}>
        {type === 'transfer' ? 'Select wallet to transfer from' : 'Select wallet to deposit to'}
      </Typography>

      <Grid rowGap={2} display="grid" className={styles.walletsList}>
        {wallets?.map((wallet) => (
          <WalletItem wallet={wallet} key={wallet.public_key} type={type} />
        ))}
      </Grid>
    </div>
  )
}

export { SelectWalletToDeposit }
