import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'

import {
  addUserFavoriteToken,
  deleteUserFavoriteToken,
  getUserFavorites,
  getUserHistory,
  updateFavoritesTokensOrder,
} from '@/api/favorites'
import { TUserFavorite } from '@/api/favorites/types'
import { EFavoritesErrorMessage } from '@/libs/enums/favorites-error-message'
import { createAsyncThunkWithControll } from '@/utils/requestsController'

type TInitialState = {
  userFavorites: TUserFavorite[] | null
  userHistory: TUserFavorite[] | null
  isLoading: boolean
  errorMessage: string | null
}

const initialState: TInitialState = {
  userFavorites: null,
  userHistory: null,
  isLoading: false,
  errorMessage: null,
}

const { thunk: fetchUserFavorites, applyExtraReducers: applyExtraReducersForFavorites } =
  createAsyncThunkWithControll(
    'favoritesTokens/fetchUserFavorites',
    async ({ networkId, isHardUpdate }: { networkId: number; isHardUpdate?: boolean }) => {
      const { data } = await getUserFavorites(networkId, isHardUpdate)
      await new Promise((resolve) => setTimeout(resolve, 3000))
      return data
    },
  )

const addFavoriteToken = createAsyncThunk(
  'favoritesTokens/addFavoriteToken',
  async ({ tokenAddress, networkId }: { tokenAddress: string; networkId: number }) => {
    const { data } = await addUserFavoriteToken(tokenAddress, networkId)

    return data
  },
)

const removeFavoriteToken = createAsyncThunk(
  'favoritesTokens/removeFavoriteToken',
  async (tokenAddress: string) => {
    const { data } = await deleteUserFavoriteToken(tokenAddress)

    return data
  },
)

const updateFavoritesOrder = createAsyncThunk(
  'favoritesTokens/updateFavoritesOrder',
  async (newOrder: { tokenAddress: string; order: number }[]) => {
    await updateFavoritesTokensOrder(newOrder)
  },
)

const fetchUserHistory = createAsyncThunk(
  'favoritesTokens/fetchUserHistory',
  async (networkId: number) => {
    const { data } = await getUserHistory(networkId)
    return data
  },
)

const handleRejectReducer = (state: TInitialState, errorMessage: EFavoritesErrorMessage) => {
  state.isLoading = false
  state.errorMessage = errorMessage
}

const favoritesTokensSlice = createSlice({
  name: 'favoritesTokens',
  initialState,
  reducers: {
    changeFavoritesOrder: (state, { payload }: PayloadAction<TUserFavorite[]>) => {
      state.userFavorites = payload
    },
  },
  extraReducers: (builder) => {
    applyExtraReducersForFavorites(builder, {
      onFulfilled: (state, { payload }) => {
        state.userFavorites = payload
        state.isLoading = false
      },
      onRejected: (state) => {
        handleRejectReducer(state, EFavoritesErrorMessage.FAILED_TO_FETCH)
      },
    })

    builder.addCase(addFavoriteToken.fulfilled, (state, { payload }) => {
      state.userFavorites = [...(state.userFavorites || []), payload]
      state.isLoading = false
    })

    builder.addCase(fetchUserHistory.fulfilled, (state, { payload }) => {
      state.userHistory = payload
    })

    builder.addCase(addFavoriteToken.rejected, (state) =>
      handleRejectReducer(state, EFavoritesErrorMessage.FAILED_TO_ADD),
    )

    builder.addCase(removeFavoriteToken.fulfilled, (state, { payload }) => {
      state.userFavorites =
        state.userFavorites?.filter((item) => item.address !== payload.tokenAddress) || []
      state.isLoading = false
    })

    builder.addCase(removeFavoriteToken.rejected, (state) =>
      handleRejectReducer(state, EFavoritesErrorMessage.FAILED_TO_REMOVE),
    )

    builder.addMatcher(
      isAnyOf(fetchUserFavorites.pending, addFavoriteToken.pending, removeFavoriteToken.pending),
      (state) => {
        state.isLoading = true
        state.errorMessage = null
      },
    )
  },
})

const { changeFavoritesOrder } = favoritesTokensSlice.actions

export {
  changeFavoritesOrder,
  favoritesTokensSlice,
  fetchUserFavorites,
  fetchUserHistory,
  addFavoriteToken,
  removeFavoriteToken,
  updateFavoritesOrder,
}
