import { StrictMode } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { WagmiProvider, createConfig, http } from 'wagmi'
import { mainnet } from 'wagmi/chains'
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors'

import { App } from '@/components/app'
import history from '@/utils/history'

import './index.scss'
import { store } from './store'

const root = createRoot(document.getElementById('root') as HTMLElement)
const projectId = import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID
const chains = [mainnet] as const

// Auth0
const onRedirectCallback = (appState?: AppState) => {
  history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname)
}

// Wagmi
const wagmiQueryClient = new QueryClient()

const metadata = {
  name: 'Web3Modal',
  description: 'BBot',
  url: 'https://app.blazingbot.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

const wagmiConfig = createConfig({
  chains,
  transports: {
    [mainnet.id]: http(),
  },
  connectors: [
    walletConnect({ projectId, metadata, showQrModal: false }),
    injected({ shimDisconnect: true }),
    coinbaseWallet({
      appName: metadata.name,
      appLogoUrl: metadata.icons[0],
    }),
  ],
})

createWeb3Modal({
  wagmiConfig,
  projectId,
  enableAnalytics: true,
  defaultChain: mainnet,
  enableOnramp: true,
  allowUnsupportedChain: false,
  themeVariables: {
    '--w3m-font-family': 'Space Grotesk, sans-serif',
    '--w3m-z-index': 1301,
    '--w3m-color-mix': '#101010',
  },
})

root.render(
  <StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        onRedirectCallback={onRedirectCallback}
        cacheLocation="localstorage"
        useRefreshTokens
        authorizationParams={{
          redirect_uri: window.location.origin,
          ...(import.meta.env.VITE_AUTH0_AUDIENCE
            ? {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                scope: import.meta.env.VITE_AUTH0_SCOPE,
              }
            : null),
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <WagmiProvider config={wagmiConfig}>
            <QueryClientProvider client={wagmiQueryClient}>
              <App />
            </QueryClientProvider>
          </WagmiProvider>
        </DndProvider>
      </Auth0Provider>
    </Provider>
  </StrictMode>,
)

export { wagmiConfig }
