import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Grid, TableCell, TableRow } from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import { TNewToken } from '@/api/tokens/types'
import styles from '@/components/token-list/libs/new-tokens-table/styles.module.scss'
import { ButtonCopy, Icon, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName, TabName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { useAppDispatch } from '@/store'
import { fetchNewCurrentToken } from '@/store/slices/chain.slice'
import { fetchUserWalletsWithTokensForce } from '@/store/slices/user.slice'

type TProperty = {
  row: TNewToken
}

const Row: FC<TProperty> = ({ row }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleChangeCurrentToken = async () => {
    if (row.status === 'DEPLOYED') return
    await dispatch(fetchNewCurrentToken(row.tokenAddress))
    dispatch(fetchUserWalletsWithTokensForce(row.tokenAddress))
    navigate(
      `${AppRoute.DASHBOARD}/${AppMode.PRO}?${TabName.DASHBOARD_NAV}=dashboard&token=${row.tokenAddress}`,
    )
  }

  return (
    <TableRow
      className={cn(styles.row, row.status === 'DEPLOYED' && styles.unclickable)}
      onClick={handleChangeCurrentToken}
    >
      <TableCell className={cn(styles.cell, styles.token)}>
        <div className={styles.tokenInfoWrapper}>
          <div className={styles.controls}>
            {row.status === 'RUGGED' && (
              <span className={styles.warning}>
                <Icon name={IconName.TRIANGLE} />
              </span>
            )}
            <Grid container flexDirection="column">
              <Typography
                variant="body2"
                className={cn(styles.symbol, row.status === 'RUGGED' && styles.redText)}
              >
                {row.tokenSymbol}
              </Typography>

              <Typography variant="body2" className={styles.name}>
                {row.tokenName}
              </Typography>
            </Grid>
          </div>
          <ButtonCopy value={row.tokenAddress} />
        </div>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.actions)}>
        <div className={styles.exchangeWrapper}>
          <Link
            to={`https://etherscan.io/token/${row.tokenAddress}`}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            <Icon name={IconName.ETHER_LOGO} />
          </Link>
          <Link
            to={`https://x.com/search?q=%24${row.tokenSymbol}`}
            target="_blank"
            className={styles.twitter}
            onClick={(e) => e.stopPropagation()}
          >
            <Icon name={IconName.TWITTER} />
          </Link>
          {row.expandedData?.exchanges
            .filter((el) => el.iconUrl)
            .slice(0, 4)
            .map((el, index) => (
              <Link
                key={el.id}
                to={`https://app.uniswap.org/swap?&inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=${row.tokenAddress}`}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  src={el.iconUrl}
                  alt={el.name}
                  className={styles.image}
                  style={{ zIndex: 4 - index }}
                ></img>
              </Link>
            ))}
        </div>
      </TableCell>
      <TableCell
        className={cn(styles.cell, styles.status, {
          [styles.redText]: row.status === 'RUGGED',
          [styles.greenText]: row.status === 'TRADING',
          [styles.orangeText]: row.status === 'DEPLOYED',
        })}
      >
        {row.status}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.listed)}>
        {moment
          .utc(row.timeCreated * 1000)
          .startOf('second')
          .fromNow()}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.tax)}>
        <div>
          <span className={styles.greenText}>B </span>
          {row.simulationResults[0]?.buyTax ? (
            <>{Math.round(+row.simulationResults[0].buyTax * -1)} %</>
          ) : (
            '-'
          )}
        </div>
        <div>
          <span className={styles.redText}>S </span>
          {row.simulationResults[0]?.sellTax ? (
            <>{Math.round(+row.simulationResults[0].sellTax * -1)} %</>
          ) : (
            '-'
          )}
        </div>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.liquidity)}>
        {row.expandedData?.liquidity ? (
          <>${formatNumber(row.expandedData.liquidity).formatted}</>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.time, styles.volume)}>
        {row.expandedData?.volume24 ? (
          <>${formatNumber(row.expandedData.volume24).formatted}</>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.marketCap)}>
        {row.expandedData?.marketCap ? (
          <>${formatNumber(row.expandedData.marketCap).formatted}</>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.price)}>
        {row.expandedData?.priceUSD ? (
          <>${formatNumber(row.expandedData.priceUSD).formatted}</>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.traders)}>
        <div>
          <span className={styles.greenText}>B </span>
          {row.expandedData?.uniqueBuys24 ? row.expandedData.uniqueBuys24 : '-'}
        </div>
        <div>
          <span className={styles.redText}>S </span>
          {row.expandedData?.uniqueSells24 ? row.expandedData.uniqueSells24 : '-'}
        </div>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.supply)}>
        $
        {row.totalSupply
          ? formatNumber(+row.totalSupply / Math.pow(10, row.decimals)).formatted
          : 0}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.deployed)}>
        <div className={styles.address}>{hideWalletAddress(row.creatorAddress)}</div>
        <div> {formatNumber(+row.creatorBalance / Math.pow(10, 18)).formatted} ETH</div>
      </TableCell>
    </TableRow>
  )
}

export { Row }
