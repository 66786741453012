import { store } from '@/store'

const getDefaultTeplate = () => {
  const userWallets = store.getState().user.userWallets

  const mainWallet = userWallets?.find((wallet) => wallet.isDefault) || userWallets?.[0]

  if (!mainWallet) {
    return null
  }

  return {
    _id: 'Fast Buy default',
    name: 'Fast Buy',
    token: null,
    isPredefined: true,
    network: {
      blockchain: 0,
      dex: null,
      custom: {
        custom_router: '',
        custom_pair: '',
        custom_factory: '',
        mapping: {
          activate: false,
          file_name: '',
        },
      },
    },
    wallets: {
      main_wallet: {
        name: 'dummy_wallet',
        public_key: '0x14dC79964da2C08b23698B3D3cc7Ca32193d9955',
      },
      wallets: [
        {
          name: mainWallet.name,
          public_key: mainWallet.public_key,
        },
      ],
    },
    operation: {
      private_tx: true,
      tx_type: 1,
      bribe_amount: 0,
      contract: {
        separate_txs: true,
      },
      gas: {
        gas_limit: 1000000,
        approve: {
          gas: 10,
          miner_tip: 7,
          auto_calculate: true,
        },
        buy: {
          gas: 10,
          miner_tip: 7,
          auto_calculate: true,
        },
      },
      pair: {
        routing: {
          mode: 2,
          path: [],
        },
        fee: {
          mode: 1,
          path_fees: [],
        },
      },
      setup: {
        buy_mode: 0,
        buy_amount: 0,
        buy_tokens: 0,
        supply_percentage: 0,
        rounds_to_buy: 1,
        slippage: 50,
        auto_approve: true,
        force_buy: true,
      },
      shields: {
        anti_fake_liquidity: false,
        anti_tax: true,
        anti_honeypot: true,
        anti_market_cap: false,
        buy_tax: 50,
        sell_tax: 70,
        maximum_liquidity: 0,
        minimum_liquidity: 0,
        maximum_market_cap: 0,
        minimum_market_cap: 0,
        anti_honeypot_mode: 0,
        anti_fake_liquidity_mode: 0,
        anti_tax_mode: 0,
        anti_price_impact: false,
        price_impact: 0,
        anti_buy_sell_ratio: false,
        buy_sell_ratio: 0,
      },
    },
    __v: 0,
  }
}

export { getDefaultTeplate }
