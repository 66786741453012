import { api } from '@/api'
import { TUserWallet } from '@/libs/types/user.type'

import {
  TGenerateWalletsPayload,
  TGenerateWalletsResponse,
  TGetUserWalletsResponse,
  TImportWalletPayload,
  TImportWalletsrResponse,
  TTokenBalanceByWalletResponse,
} from './types'

const BASE_URL = 'wallets'

const getUserWallets = (userId: string) => api.get<TGetUserWalletsResponse>(`${BASE_URL}/${userId}`)

const getUserWalletsFormatted = async (userId: string) => {
  const { data } = await getUserWallets(userId)

  const walletsInfo: TUserWallet[] = data.walletInfo.map((item) => {
    const { _id, ...rest } = item
    return { ...rest, id: _id }
  })

  data.walletBalance.forEach((balanceItem) => {
    balanceItem.wallet_balances.forEach((walletBalance) => {
      const addressLower = walletBalance.address.toLowerCase()
      const currentWallet = walletsInfo.find(
        (infoItem) => infoItem.public_key.toLowerCase() === addressLower,
      )
      if (!currentWallet) return

      currentWallet.balance = walletBalance.balance
      currentWallet.balanceFormatted = walletBalance.balance_formatted
      currentWallet.chain = balanceItem.chain
      currentWallet.chain_id = balanceItem.chain_id
    })
  })

  const sorterByPriority = walletsInfo.sort((a, b) => a.priority - b.priority)

  return sorterByPriority
}

const changeUserWalletsOrder = async (
  updatedWalletsOrder: { walletId: string; newPriority: number }[],
) => api.patch(`${BASE_URL}/updatePriorities`, updatedWalletsOrder)

const setWalletAsDefault = async (userId: string, publicKey: string) =>
  api.patch(`${BASE_URL}/setDefault/${userId}/${publicKey}`)

const renameWallet = async (userId: string, publicKey: string, walletName: string) =>
  api.patch(`${BASE_URL}/rename/${userId}/${publicKey}`, { newName: walletName })

const deleteWallet = async (userId: string, publicKey: string) =>
  api.delete(`${BASE_URL}/${userId}/${publicKey}`)

const generateWallets = (payload: TGenerateWalletsPayload) =>
  api.post<TGenerateWalletsResponse[]>(`${BASE_URL}`, payload)

const getTokenBalanceByWallet = async (tokenAddress: string, networkId: number) =>
  api.get<TTokenBalanceByWalletResponse[]>(
    `${BASE_URL}/get-token-balances-for-wallets/${tokenAddress}/${networkId}`,
  )

const importWallets = (payload: TImportWalletPayload[]) =>
  api.post<TImportWalletsrResponse>(`${BASE_URL}/import`, { wallets: payload })

export {
  getUserWallets,
  getUserWalletsFormatted,
  changeUserWalletsOrder,
  setWalletAsDefault,
  renameWallet,
  deleteWallet,
  generateWallets,
  getTokenBalanceByWallet,
  importWallets,
}
