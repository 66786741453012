import { api } from '@/api'

import {
  TFilterTokensResponse,
  TNewToken,
  TPairStatsResponse,
  TTokenExchangesAndSocialResponse,
  TTokenPriceResponse,
  TTokenTransactionFiltered,
  TTokenTransactionResponse,
  TTopTokensListResponse,
} from './types'

const BASE_URL = 'tokens'

const getTokenPriceInUsd = (address: string, networkId: number) =>
  api.get<TTokenPriceResponse>(`${BASE_URL}/price/${address}/${networkId}`)

const getTopTokensList = (networkId: number) =>
  api.get<TTopTokensListResponse>(`${BASE_URL}/getTopTokensList/${networkId}`)

const getNewTokensList = (networkId: number, offset: number) =>
  api.get<TNewToken[]>(`${BASE_URL}/getNewTokensList/${networkId}?offset=${offset}`)

const getTokenTransactions = (
  address: string,
  networkId: number,
  sorting: string,
  cursor?: string,
  maker?: string,
) =>
  api.get<TTokenTransactionResponse>(
    `${BASE_URL}/transactions/${address}/${networkId}?direction=${sorting}&cursor=${cursor}&maker=${maker}`,
  )

const getFilterTokens = (addressOrPhrase: string, networkId: number) =>
  api.get<TFilterTokensResponse>(
    `${BASE_URL}/filter-tokens/${addressOrPhrase}?networkId=${networkId}`,
  )

const getTransactionsEnsNames = (body: any) =>
  api.post<any>(`${BASE_URL}/transactions/ens/name`, body)

const getTokenTransactionsFiltered = async (
  address: string,
  networkId: number,
  sorting: string,
  cursor?: string,
  maker?: string,
): Promise<TTokenTransactionResponse> => {
  const { data: transactions } = await getTokenTransactions(
    address,
    networkId,
    sorting,
    cursor,
    maker,
  )
  return {
    cursor: transactions.cursor,
    items: transactions.items.map((item) => {
      const { data, ...info } = item
      return { ...info, ...data } as TTokenTransactionFiltered
    }),
  }
}

const getTokenExchangesAndSocial = (address: string, networkId: number) =>
  api.get<TTokenExchangesAndSocialResponse>(`${BASE_URL}/exchanges/${address}/${networkId}`)

const getDetailedPairStats = (address: string, networkId: number) =>
  api.get<TPairStatsResponse>(`${BASE_URL}/pair-stats/${address}/${networkId}`)

const removeTokenFromHoneypot = (tokenAddress: string, networkId: number) =>
  api.delete(`${BASE_URL}/remove-token-from-honeypot/${tokenAddress}/${networkId}`)

export {
  getTokenPriceInUsd,
  getTokenTransactions,
  getTokenTransactionsFiltered,
  getTokenExchangesAndSocial,
  getDetailedPairStats,
  getFilterTokens,
  getTopTokensList,
  getNewTokensList,
  getTransactionsEnsNames,
  removeTokenFromHoneypot,
}
