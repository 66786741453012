import { FC } from 'react'

import { useAppMode } from '@/hooks/useAppMode'
import { ListButton } from '@/libs/common'
import { ButtonLink } from '@/libs/common/button-link'
import { AppRoute, IconName } from '@/libs/enums'
import { TUserWallet } from '@/libs/types/user.type'

import styles from './styles.module.scss'

type TProperty = {
  wallet: TUserWallet | undefined
}
const TransferTab: FC<TProperty> = ({ wallet }) => {
  const { mode } = useAppMode()
  return (
    <div className={styles.container}>
      <ButtonLink
        to={`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.TRANSFER}/${wallet?.public_key}`}
      >
        <ListButton
          title="Transfer"
          description="You will be able to select one of your wallets inside the bot to send to, or send to external wallet."
          iconName={IconName.WITHDRAW}
        />
      </ButtonLink>
      <ButtonLink
        to={`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.DISPERSE_MULTI}/${wallet?.public_key}`}
      >
        <ListButton
          title="Disperse Multi"
          description="You will be able to select multiple wallets inside the bot to send to equal amounts."
          iconName={IconName.CROSS}
        />
      </ButtonLink>
      <ButtonLink
        to={`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.COLLECT_MULTI}/${wallet?.public_key}`}
      >
        <ListButton
          title="Collect Multi"
          description="You will be able to select receiving wallet and collect the funds from as many wallets as you have in the bot in one go."
          iconName={IconName.CROSS}
        />
      </ButtonLink>
    </div>
  )
}

export { TransferTab }
