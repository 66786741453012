import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { deleteBuyTemplate } from '@/api/templates'
import { Spinner } from '@/components/spinner/spinner'
import { Button, ButtonIcon, Icon, Popover, type TOnChangeVisible, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName, SpinnerSize } from '@/libs/enums'
import { handleError } from '@/libs/helper/handleError'
import { useAppDispatch } from '@/store'
import { fetchUserBuyTemplates } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

type TProps = {
  title: string
  isDefault: boolean
  templateId: string
}

const TemplateItem: FC<TProps> = ({ title, isDefault, templateId }) => {
  const [deletePopup, setDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const handleEdit = () => {
    navigate(
      `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.MANUAL_BUY_TEMPLATE}/${templateId}`,
    )
  }

  const handleDelete = async () => {
    try {
      setIsDeleteLoading(true)
      await deleteBuyTemplate(templateId)
      await dispatch(fetchUserBuyTemplates()).unwrap()
    } catch (err) {
      handleError(err)
    } finally {
      setIsDeleteLoading(false)
    }
  }

  return (
    <Grid className={styles.templateItemContainer}>
      <Grid container gap={1} alignItems="center">
        <Typography
          variant="body2"
          className={cn(styles.title, {
            [styles.default]: isDefault,
          })}
        >
          {title}
        </Typography>

        {/* TODO: Add default templates */}
        {/* <Button
          styleVariant="basic"
          className={cn(isDefault ? styles.defaultDescription : styles.description)}
          onClick={!isDefault ? setAsDefault : undefined}
        >
          {isDefault ? 'Default' : 'Set as default'}
        </Button> */}
      </Grid>

      <Grid container gap={1} className={styles.buttons}>
        <Button
          tooltipTitle="Edit"
          styleVariant="basic"
          borderless
          className={styles.button}
          onClick={handleEdit}
        >
          <Icon name={IconName.EDIT_SMALL} />
        </Button>

        <Grid className={styles.popupContainer}>
          <Button
            tooltipTitle="Delete"
            styleVariant="basic"
            borderless
            className={styles.button}
            disabled={isDeleteLoading}
            type="button"
            onClick={(e) => setDeletePopup({ visible: true, anchorE: e.currentTarget })}
          >
            {isDeleteLoading ? (
              <Spinner size={SpinnerSize.SMALL} />
            ) : (
              <Icon name={IconName.DELETE_RED} />
            )}
          </Button>

          <Popover
            className={styles.popover}
            popoverState={deletePopup}
            onChangeVisible={setDeletePopup}
          >
            <Typography variant="body2" className={styles.title}>
              Confirm Delete
            </Typography>
            <Typography variant="body2">
              Are you sure you want to delete {title} Template?
            </Typography>
            <div className={styles.popoverButtons}>
              <ButtonIcon
                onClick={() => {
                  setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                }}
              >
                <Typography variant="body2" className={styles.cancel}>
                  Cancel
                </Typography>
              </ButtonIcon>
              <Button
                className={styles.confirm}
                styleVariant="red"
                onClick={() => {
                  handleDelete()
                  setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                }}
              >
                Delete
              </Button>
            </div>
          </Popover>
        </Grid>
      </Grid>
    </Grid>
  )
}

export { TemplateItem }
