import { AppRoute } from '@/libs/enums'

const publicRoutes = [
  `/`,
  `${AppRoute.SELECT_PLAN}`,
  `${AppRoute.SETUP_WALLETS}`,
  `${AppRoute.GENERATE_WALLETS}`,
  `${AppRoute.IMPORT_KEYS}`,
  `${AppRoute.IMPORT_WALLET_FILE}`,
  `${AppRoute.MASTER_PASSWORD_ADVANTAGES}`,
  `${AppRoute.CREATE_MASTER_PASSWORD}`,
  `${AppRoute.SETUP_SUCCESS}`,
]

export { publicRoutes }
