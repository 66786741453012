import { Grid } from '@mui/material'

import { Button, ButtonCopy, Typography } from '@/libs/common'
import { useAppSelector } from '@/store'

const Affiliates = () => {
  const commissionTier = useAppSelector((state) => state.user.userCommissionTier)
  const userData = useAppSelector((state) => state.user.userData)

  const webAffiliateLink = `${window.location.origin}?affiliateId=${userData?.affiliate_id}`
  const tgAffiliateLink = `${import.meta.env.VITE_TG_URL}?start=${userData?.affiliate_id}`

  return (
    <Grid>
      <Typography variant="h1" align="center">
        Affiliates
      </Typography>
      <Grid display="flex" marginBottom={1} marginTop={1}>
        <Typography>My commission tier:</Typography>
        <Typography marginLeft={1} textColor="white">
          {commissionTier ? `${commissionTier.name} - ${commissionTier.percent}%` : '-'}
        </Typography>
      </Grid>
      <Grid display="flex" alignItems="center" marginTop={1}>
        <Typography>My web invite link:</Typography>
        <Typography marginLeft={1} marginRight={1} textColor="white">
          {webAffiliateLink}
        </Typography>
        <ButtonCopy value={webAffiliateLink} />
      </Grid>
      <Grid display="flex" alignItems="center" marginBottom={2} marginTop={1}>
        <Typography>My Telegram invite link:</Typography>
        <Typography marginLeft={1} marginRight={1} textColor="white">
          {tgAffiliateLink}
        </Typography>
        <ButtonCopy value={tgAffiliateLink} />
      </Grid>
      <Button
        styleVariant="black"
        onClick={() => window.open(import.meta.env.VITE_AFFILIATES_DASHBOARD_URL, '_blank')}
      >
        Open Affiliates Dashboard
      </Button>
    </Grid>
  )
}

export { Affiliates }
