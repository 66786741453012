import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { TUserFavorite } from '@/api/favorites/types'
import { CustomToast } from '@/components/custom-toast'
import {
  Accordion,
  AccordionListElement,
  ButtonIcon,
  TokenPlaceholder,
  Typography,
} from '@/libs/common'
import { DragAndDropList } from '@/libs/common/drag-and-drop-list'
import { AppMode, AppRoute, IconName, TabName } from '@/libs/enums'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchNewCurrentToken } from '@/store/slices/chain.slice'
import {
  changeFavoritesOrder,
  fetchUserFavorites,
  removeFavoriteToken,
  updateFavoritesOrder,
} from '@/store/slices/favorites-tokens.slice'
import { fetchUserWalletsWithTokensForce } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const FavoritesAccordion: FC = () => {
  const navigate = useNavigate()
  const userFavorites = useAppSelector((state) => state.favoritesTokens.userFavorites)
  const userHistory = useAppSelector((state) => state.favoritesTokens.userHistory)
  const networkId = useAppSelector((state) => state.chain.currentChain.id)
  const favoritesLoading = useAppSelector((state) => state.favoritesTokens.isLoading)
  const favoritesErrorMessage = useAppSelector((state) => state.favoritesTokens.errorMessage)
  const dispatch = useAppDispatch()

  const [isSomeElementDragging, setIsSomeElementDragging] = useState(false)
  const [items, setItems] = useState<TUserFavorite[] | null>(null)
  const [isFavorites, setIsFavorites] = useState(true)

  useEffect(() => {
    if (favoritesErrorMessage) {
      CustomToast('error', favoritesErrorMessage)
    }

    if (isFavorites) {
      if (userFavorites) setItems(userFavorites)
    } else {
      if (userFavorites) setItems(userHistory)
    }
  }, [userFavorites, userHistory, favoritesErrorMessage, isFavorites])

  const handleChangeOrder = () => {
    if (!items) return

    const newList = items.map(({ address }, index) => ({
      tokenAddress: address,
      order: index,
    }))
    dispatch(changeFavoritesOrder(items))
    dispatch(updateFavoritesOrder(newList))
  }

  const handleRemoveFavoriteToken = (tokenAddress: string) => {
    dispatch(removeFavoriteToken(tokenAddress))
  }

  const setNewCurrentToken = (tokenAddress: string) => {
    dispatch(fetchNewCurrentToken(tokenAddress))
    dispatch(fetchUserWalletsWithTokensForce(tokenAddress))
    navigate(
      `${AppRoute.DASHBOARD}/${AppMode.PRO}?${TabName.DASHBOARD_NAV}=dashboard&token=${tokenAddress}`,
    )
  }

  const renderItem = useCallback(
    (item: TUserFavorite, isDragging: boolean) => (
      <AccordionListElement
        enableBurger={isFavorites}
        className={styles.favoriteItem}
        disableHover={isSomeElementDragging && !isDragging}
        onClick={() => {
          setNewCurrentToken(item.address)
        }}
      >
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
          <div className={styles.controls}>
            {isFavorites && (
              <ButtonIcon
                icon={IconName.CROSSED_STAR}
                onClick={(e) => {
                  e.stopPropagation()
                  handleRemoveFavoriteToken(item.address)
                }}
              />
            )}

            <div className={styles.iconWrapper}>
              {item.tokenImg ? (
                <img src={item.tokenImg} className={styles.icon} loading="lazy" alt={item.name} />
              ) : (
                <TokenPlaceholder token={item} size="35px" />
              )}

              <img
                src={item.pairImg}
                loading="lazy"
                alt="network logo"
                className={styles.network}
              />
            </div>

            <Grid container flexDirection="column">
              <Typography variant="body2" className={styles.symbol}>
                {item.symbol}
              </Typography>

              <Typography variant="body2" className={styles.name}>
                {item.name}
              </Typography>
            </Grid>
          </div>

          <Grid className={styles.info}>
            <Typography variant="body2" className={styles.price}>
              ${formatTokenPrice(item.priceUSD).formatted}
            </Typography>

            <Typography
              variant="body2"
              textAlign="end"
              className={cn(Number(item.change24) > 0 ? styles.profit : styles.loss)}
            >
              {item.change24}%
            </Typography>
          </Grid>
        </Grid>
      </AccordionListElement>
    ),
    [isSomeElementDragging, isFavorites],
  )

  const handleRefresh = () => {
    dispatch(fetchUserFavorites({ networkId, isHardUpdate: true }))
  }

  return (
    <Accordion
      title={
        <div className={styles.titleGroup}>
          <Typography
            className={cn(styles.titleTab, isFavorites && styles.activeTab)}
            onClick={(e) => {
              e.stopPropagation()
              setIsFavorites(true)
            }}
          >
            Favorites
          </Typography>
          <span className={styles.titleTab}>/</span>
          <Typography
            className={cn(styles.titleTab, !isFavorites && styles.activeTab)}
            onClick={(e) => {
              e.stopPropagation()
              setIsFavorites(false)
            }}
          >
            History
          </Typography>
        </div>
      }
      enableRefreshing={isFavorites}
      onRefreshingClick={handleRefresh}
      refreshLoading={favoritesLoading}
      contentWithScroll="favorites"
    >
      {items?.length ? (
        isFavorites ? (
          <DragAndDropList
            propOfItemForKey="address"
            data={items}
            setData={setItems}
            renderItem={renderItem}
            onDragStateChange={(state) => {
              setIsSomeElementDragging(state)
            }}
            onOrderChanged={handleChangeOrder}
          />
        ) : (
          items.map((item) => renderItem(item, false))
        )
      ) : (
        <Typography variant="body1" component="span" textColor="grey" align="center">
          No favorites
        </Typography>
      )}
    </Accordion>
  )
}

export { FavoritesAccordion }
