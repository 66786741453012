import { FC, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { jwtDecode } from 'jwt-decode'
import moment from 'moment'

import { setTokenGenerator } from '@/api'
import { createUser } from '@/api/user'
import { EAuthQueryParams } from '@/components/app/libs/enums/auth-query-params.enum'
import { useAuth } from '@/hooks/auth.hook'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config'
import { publicRoutes } from '@/libs/configs/public-routes.config'
import { AppMode, AppRoute, LocalStorageItem } from '@/libs/enums'
import { getErrorMessage } from '@/libs/helper'
import { useAppDispatch, useAppSelector } from '@/store'
import {
  fetchUserBuyTemplates,
  fetchUserByIdWithWallets,
  fetchUserCommissionTier,
  setUser,
} from '@/store/slices/user.slice'

const AFFILIATE_EXPIRES_IN_SECONDS = 420

type TAffiliateInfo = {
  affiliateId: string
  expires: number
}

type TProps = {
  setIsAppLoaded: (value: boolean) => void
}

const AuthController: FC<TProps> = ({ setIsAppLoaded }) => {
  const userData = useAppSelector((state) => state.user.userData)
  const dispatch = useAppDispatch()
  const timeout: NodeJS.Timeout | null = null
  const interval: NodeJS.Timeout | null = null

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { mode } = useParams()

  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    user: authUser,
  } = useAuth()

  const signUp = async (userId: string, name?: string) => {
    const affiliateInfoRaw = localStorage.getItem(EAuthQueryParams.AFFILIATE_ID)
    localStorage.removeItem(EAuthQueryParams.AFFILIATE_ID)
    const affiliateInfo = affiliateInfoRaw ? (JSON.parse(affiliateInfoRaw) as TAffiliateInfo) : null
    let affiliateId = ''

    if (!!affiliateInfo && moment(affiliateInfo.expires).diff(moment(), 'seconds') > 0) {
      affiliateId = affiliateInfo.affiliateId
    }

    const { data } = await createUser({
      authId: userId,
      master_password: '',
      name: name || 'Unknown',
      refferedByAffiliateId: affiliateId,
    })
    dispatch(setUser(data))
    dispatch(fetchUserCommissionTier(undefined))
    navigate(AppRoute.SETUP_WALLETS, { replace: true })
    setIsAppLoaded(true)
  }

  const handleAuth = async (): Promise<void> => {
    if (isLoading) {
      return
    }

    const affiliateId = searchParams.get(EAuthQueryParams.AFFILIATE_ID)

    if (!isAuthenticated) {
      if (!affiliateId) {
        localStorage.removeItem(EAuthQueryParams.AFFILIATE_ID)
      }
      loginWithRedirect()
    } else if (isAuthenticated && !userData) {
      let token: string | null = null

      try {
        token = await getAccessTokenSilently()
        setTokenGenerator(getAccessTokenSilently as any)
      } catch (err) {
        const message: string = getErrorMessage(err)
        if (message?.includes('Missing Refresh Token')) {
          await new Promise((resolve) => setTimeout(resolve, 200))
          return handleAuth()
        }
      }

      if (!token) {
        return
      }

      const decoded = jwtDecode(token)
      const userId = decoded?.sub as string
      const userName = authUser?.username || authUser?.nickname || authUser?.name

      try {
        const { userData, userWallets } = await dispatch(fetchUserByIdWithWallets(userId)).unwrap()
        if (userData) {
          const wrappedTon = DEFAULT_TOKEN
          const existToken = localStorage.getItem(LocalStorageItem.TOKEN_ADDRESS)

          if (userData.master_password) {
            // Sign up was finished
            dispatch(fetchUserBuyTemplates())
            dispatch(fetchUserCommissionTier(undefined))

            if (
              import.meta.env.VITE_IS_TEST_INSTANCE !== 'true' &&
              publicRoutes.includes(location.pathname)
            ) {
              navigate(
                `${AppRoute.DASHBOARD}/${mode || AppMode.PRO}?token=${existToken || wrappedTon}`,
                {
                  replace: true,
                },
              )
            }
          } else {
            // Sign up was interrupted
            navigate(
              userWallets.length ? AppRoute.CREATE_MASTER_PASSWORD : AppRoute.SETUP_WALLETS,
              { replace: true },
            )
          }
          setIsAppLoaded(true)
        } else {
          await signUp(userId, userName)
        }
      } catch (err) {
        if (((err as any).message || '').includes('401')) {
          await signUp(userId, userName)
        }
      }
    }
  }

  useEffect(() => {
    const affiliateId = searchParams.get(EAuthQueryParams.AFFILIATE_ID)

    if (affiliateId) {
      localStorage.setItem(
        EAuthQueryParams.AFFILIATE_ID,
        JSON.stringify({
          affiliateId,
          expires: moment().add(AFFILIATE_EXPIRES_IN_SECONDS, 'seconds').valueOf(),
        }),
      )
    }
  }, [])

  useEffect(() => {
    handleAuth()
    return () => {
      if (timeout) clearTimeout(timeout)
      if (interval) clearInterval(interval)
    }
  }, [isAuthenticated, isLoading, userData])

  return <></>
}

export { AuthController }
