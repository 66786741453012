import { FC, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import Modal from '@mui/material/Modal'
import cn from 'classnames'

import { useAppMode } from '@/hooks/useAppMode'
import { ButtonIcon } from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'
import { getEndOfPath } from '@/libs/helper/getEndOfPath'
import { useAppDispatch, useAppSelector } from '@/store'
import { toggleSkipCleanUnconfirmedWallets } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const UNCONFIRMED_WALLETS_ROUTES: string[] = [
  AppRoute.SELECT_WALLET_COUNT,
  AppRoute.WALLET_MANAGEMENT,
]

const wideModals: string[] = [AppRoute.IMPORT_WALLETS]

const ModalPage: FC = () => {
  const { mode } = useAppMode()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const isShareProfit = location.pathname.endsWith('share-profit')
  const isWideModal = wideModals.some((item) => location.pathname.endsWith(item))
  const tokenAddress = useAppSelector((state) => state.chain.currentToken?.token.address)

  useEffect(() => {
    if (AppRoute.WALLET_MANAGEMENT.includes(getEndOfPath(location.pathname))) {
      dispatch(toggleSkipCleanUnconfirmedWallets(false))
    }
  }, [location.pathname])

  const handleClose = () => {
    if (UNCONFIRMED_WALLETS_ROUTES.includes(getEndOfPath(location.pathname))) {
      dispatch(toggleSkipCleanUnconfirmedWallets(false))
    }
    navigate(`${AppRoute.DASHBOARD}/${mode}${tokenAddress ? `?token=${tokenAddress}` : ''}`)
  }

  return (
    <Modal open disableEnforceFocus onClose={handleClose} id="modal-wrapper">
      <div
        id="modal"
        className={cn(
          styles.container,
          !isShareProfit ? styles.containerModal : styles.containerShareWidth,
          { [styles.wide]: isWideModal },
        )}
      >
        <ButtonIcon icon={IconName.CLOSE} className={styles.close} onClick={handleClose} />
        <Outlet />
      </div>
    </Modal>
  )
}

export { ModalPage }
