import { api } from '@/api'
import { TUser } from '@/libs/types'

import { TCreateUserBody, TUpdateUserBody } from './types'

const BASE_URL = 'user'

const getUserById = (userId: string) => api.get<TUser>(`${BASE_URL}/${userId}`)

const getCurrentUser = () => api.get<TUser>(`${BASE_URL}/current`)

const createUser = (body: TCreateUserBody) => api.post<TUser>(`${BASE_URL}`, body)

const updateUser = (userId: string, body: TUpdateUserBody) =>
  api.patch<TUser>(`${BASE_URL}/${userId}`, body)

export { getUserById, getCurrentUser, createUser, updateUser }
