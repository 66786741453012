import { FC } from 'react'

import MUIButton, { ButtonProps as MUIButtonProps } from '@mui/material/Button'
import cn from 'classnames'

import { Spinner } from '@/components/spinner/spinner'
import { Icon } from '@/libs/common/icon'
import { Tooltip } from '@/libs/common/tooltip/index'
import { IconName, SpinnerSize } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import styles from './styles.module.scss'

type TStyleVariant =
  | 'yellow'
  | 'purple'
  | 'black'
  | 'red'
  | 'basic'
  | 'borderless-text'
  | 'borderless-gold'
  | 'borderless-red'

type TButtonProps = MUIButtonProps & {
  styleVariant?: TStyleVariant
  outlined?: boolean
  borderless?: boolean
  iconButton?: boolean
  transparent?: boolean
  contentMaxWidth?: boolean
  iconStartName?: ValueOf<typeof IconName>
  iconEndName?: ValueOf<typeof IconName>
  isLoading?: boolean
  tooltipTitle?: string
}

const Button: FC<TButtonProps> = ({
  children,
  className,
  variant = 'contained',
  styleVariant = 'yellow',
  outlined = false,
  borderless = false,
  iconButton = false,
  transparent = false,
  contentMaxWidth = false,
  iconStartName,
  iconEndName,
  disabled,
  isLoading,
  tooltipTitle,
  ...props
}) => {
  const button = (
    <MUIButton
      variant={variant}
      className={cn(
        styles.root,
        styles[styleVariant],
        {
          [styles.outlined]: outlined,
          [styles.borderless]: borderless,
          [styles.iconButton]: iconButton,
          [styles.transparent]: transparent,
        },
        className,
      )}
      disabled={disabled || isLoading}
      {...props}
    >
      {iconStartName && <Icon name={iconStartName} />}
      <span className={cn(styles.textContent, { [styles.contentMaxWidth]: contentMaxWidth })}>
        {isLoading ? <Spinner size={SpinnerSize.SMALL} /> : children}
      </span>
      {iconEndName && <Icon name={iconEndName} />}
    </MUIButton>
  )

  return tooltipTitle ? (
    <Tooltip title={tooltipTitle} withIcon={false}>
      {button}
    </Tooltip>
  ) : (
    button
  )
}

export { Button, type TButtonProps }
