import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import cn from 'classnames'

import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Button, ButtonIcon, Icon, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName, WalletAccordionVariant } from '@/libs/enums'

import { FavoritesAccordion } from './libs/components/favorites-accordion'
import { HoldingsAccordion } from './libs/components/holdings-accordion'
import { TemplatesAccordion } from './libs/components/templates-accordion'
import { WalletAccordion } from './libs/components/wallet-accordion'
import styles from './styles.module.scss'

const Sidebar = () => {
  const navigate = useNavigate()
  const windowDimension = useWindowDimensions()
  const [isOpen, setIsOpen] = useState(false)
  const isResponsive = windowDimension.width <= 1199

  return (
    <>
      <aside className={cn(styles.aside, { [styles.responsive]: isResponsive })}>
        <div
          className={cn(styles.container, {
            [styles.responsive]: isResponsive,
            [styles.close]: isResponsive && !isOpen,
          })}
        >
          {isResponsive && (
            <>
              <ButtonIcon
                icon={IconName.SIDEBAR_COLLAPSED_BUTTON}
                className={cn(styles.controlMobile, { [styles.open]: isOpen })}
                onClick={() => setIsOpen((state) => !state)}
              />
              <div className={styles.mobileHeader}>
                <Typography variant="h5" color="#fff">
                  User hub
                </Typography>
                <ButtonIcon icon={IconName.CLOSE} onClick={() => setIsOpen(false)} />
              </div>
            </>
          )}
          {isResponsive && !isResponsive && (
            <ButtonIcon
              icon={isOpen ? IconName.CHEVRON_LEFT : IconName.CHEVRON_RIGHT}
              className={cn(styles.control, { [styles.open]: isOpen })}
              onClick={() => setIsOpen((state) => !state)}
            />
          )}

          <div className={styles.content}>
            <div className={styles.buttons}>
              <Button
                styleVariant="black"
                className={styles.button}
                onClick={() =>
                  navigate(
                    `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.SELECT_WALLET_TO_DEPOSIT}`,
                  )
                }
              >
                <Icon name={IconName.DOWNLOAD} /> Deposit
              </Button>
              <Button
                styleVariant="black"
                className={styles.button}
                onClick={() =>
                  navigate(
                    `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.TRANSFER_WALLETS}`,
                  )
                }
              >
                <Icon name={IconName.WITHDRAW} />
                Transfer
              </Button>
              <Button
                styleVariant="black"
                className={styles.button}
                onClick={() =>
                  navigate(
                    `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.AFFILIATES}`,
                  )
                }
              >
                Affiliates
              </Button>
            </div>
            <div>
              <WalletAccordion variant={WalletAccordionVariant.WALLETS} />
              <TemplatesAccordion />
              {/* <WalletAccordion variant={WalletAccordionVariant.TRANSFER_WALLETS} /> */}
              <FavoritesAccordion />
              <HoldingsAccordion />
            </div>
          </div>
        </div>
      </aside>

      <div
        className={cn(styles.overlay, { [styles.visible]: isOpen })}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      />
    </>
  )
}

export { Sidebar }
