const isEquals = (sourceArray: string[] | number[], targetArray: string[] | number[]) => {
  const array1 = sourceArray.slice().sort()
  const array2 = targetArray.slice().sort()
  return (
    array1.length === array2.length &&
    array1.every(function (value, index) {
      return value === array2[index]
    })
  )
}

export { isEquals }
