import { FC, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import StarRateIcon from '@mui/icons-material/StarRate'
import { Stack } from '@mui/material'
import cls from 'classnames'

import { CustomToast } from '@/components/custom-toast'
import { Button, ButtonCopy, Icon, TokenPlaceholder, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { ITokenInfo } from '@/libs/types/token-info-response.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { addFavoriteToken, removeFavoriteToken } from '@/store/slices/favorites-tokens.slice'

import { TokenInfoLite } from './libs/components/token-info-lite'
import { TokenInfoPro } from './libs/components/token-info-pro'
import styles from './styles.module.scss'

type TProps = {
  selectedToken: ITokenInfo
}

const SelectedTokenInfo: FC<TProps> = ({ selectedToken }) => {
  const { mode } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const userFavoritesToken = useAppSelector((state) => state.favoritesTokens.userFavorites)
  const favoritesErrorMessage = useAppSelector((state) => state.favoritesTokens.errorMessage)
  const simulation = useAppSelector((state) => state.chain.currentTokenSimulationWebsocket)

  useEffect(() => {
    if (favoritesErrorMessage) {
      CustomToast('error', favoritesErrorMessage)
    }
  }, [favoritesErrorMessage])

  const isUserFavoriteToken = userFavoritesToken?.some(
    ({ address }) => address === selectedToken.token.address,
  )

  const handleOpenModal = () => {
    navigate(`${AppRoute.MODAL}/${AppRoute.TOKEN_INFO}`)
  }

  const secondaryToken =
    selectedToken.token.address === selectedToken.pair.token0Data.address
      ? selectedToken.pair.token1Data
      : selectedToken.pair.token0Data

  const addUserFavoriteToken = () => {
    if (!isUserFavoriteToken) {
      dispatch(
        addFavoriteToken({
          tokenAddress: selectedToken.token.address,
          networkId: selectedToken.exchanges[0].networkId,
        }),
      )
    } else {
      dispatch(removeFavoriteToken(selectedToken.token.address))
    }
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      className={cls(styles.container, mode === 'lite' && styles.containerLite)}
    >
      <Stack className={styles.token}>
        <Stack direction="column" gap={0.5} className={styles.tokenDescrWrapper}>
          <Typography variant="body1" className={styles.tokenDescr}>
            {selectedToken.token.info.imageSmallUrl ? (
              <img
                className={styles.tokenLogo}
                src={selectedToken.token.info.imageSmallUrl}
                alt={selectedToken.token.name + ' token'}
              />
            ) : (
              <TokenPlaceholder token={selectedToken.token} />
            )}

            <Typography variant="body1" className={styles.tokenName}>
              <span>
                {selectedToken.token.name} / {secondaryToken.symbol}
              </span>
            </Typography>

            <Button onClick={addUserFavoriteToken} className={styles.favoriteBtn}>
              <StarRateIcon
                className={styles.favoriteIcon}
                fontSize="small"
                sx={{
                  fill: isUserFavoriteToken ? '#FFC800' : 'transparent',
                  stroke: isUserFavoriteToken ? 'transparent' : '#979797',
                }}
              />
            </Button>
          </Typography>

          <Typography variant="body1" className={styles.pairAddress}>
            <ButtonCopy value={selectedToken.token.address} />

            {hideWalletAddress(selectedToken.token.address, 7)}
          </Typography>
        </Stack>

        {mode === AppMode.PRO && (
          <Stack className={styles.linksWrapper}>
            <div className={styles.links}>
              <Link
                to={selectedToken?.socialLinks.website || ''}
                target="_blank"
                className={cls(styles.socialLink, {
                  [styles.disabledLink]: !selectedToken?.socialLinks.website,
                })}
              >
                <Icon name={IconName.LANGUAGE} />
              </Link>
              <Link
                to={selectedToken?.socialLinks.telegram || ''}
                target="_blank"
                className={cls(styles.socialLink, {
                  [styles.disabledLink]: !selectedToken?.socialLinks.telegram,
                })}
              >
                <Icon name={IconName.EXCLUDE} />
              </Link>
              <Link
                to={selectedToken?.socialLinks.twitter || ''}
                target="_blank"
                className={cls(styles.socialLink, {
                  [styles.disabledLink]: !selectedToken?.socialLinks.twitter,
                })}
              >
                <Icon name={IconName.TWITTER} />
              </Link>
            </div>

            <div className={styles.searchLink}>
              <Link to={`https://x.com/search?q=%24${selectedToken.token.name}`} target="_blank">
                <Icon name={IconName.SEARCH} />
              </Link>
            </div>
          </Stack>
        )}
      </Stack>

      {mode === AppMode.PRO ? (
        <TokenInfoPro
          selectedToken={selectedToken}
          simulation={simulation}
          handleOpenModal={handleOpenModal}
        />
      ) : (
        <TokenInfoLite
          selectedToken={selectedToken}
          simulation={simulation}
          handleOpenModal={handleOpenModal}
        />
      )}
    </Stack>
  )
}

export { SelectedTokenInfo }
