import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { EBuyMode } from '@/libs/enums/buy-mode.enum'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { getPrioritiesDefault } from '@/libs/helper/getPrioritiesDefault'
import { stringsToNumbersInObject } from '@/libs/helper/stringsToNumbersInObject'
import { TManualBuyForm } from '@/libs/types/manual-buy-form.type'
import { store } from '@/store'

type TWallet = {
  name: string
  public_key: string
}

type TProps = {
  data: TManualBuyForm
  wallets: TWallet[]
  main_wallet: TWallet
  isTemplate?: boolean
}

const createManualBuyPayload = ({ data, wallets, main_wallet, isTemplate = false }: TProps) => {
  const defaultPriorities = getPrioritiesDefault()
  const userData = store.getState().user.userData
  const currentToken = store.getState().chain.currentToken
  const currentChain = store.getState().chain.currentChain
  const currentDex = store.getState().chain.currentTokenSimulationWebsocket?.l.d

  if (!userData) return

  const shields = stringsToNumbersInObject(data.shields, 0)

  // TODO: Add enum for buy_mode
  let buy_mode = EBuyMode.CHAIN_CURRENCY
  if (data.advancedBuy.maxTxOrFail) {
    buy_mode = EBuyMode.MAX_TRANSACTION
  } else if (data.advancedBuy.minPercentTokenOrFail) {
    buy_mode = data.advancedBuy.minPercentTokenOrFail.includes('%')
      ? EBuyMode.MIN_PERCENT
      : EBuyMode.MIN_TOKENS
  }

  return {
    userId: userData._id,
    token: isTemplate || !currentToken ? null : currentToken.token.address, // Token address a user wants to buy
    network: {
      blockchain: currentChain.indexerChainId, // Current blockchain id
      dex: isTemplate || !currentDex ? null : +currentDex, // Selected dex service
      // The custom field is not used so far (but it's required by the Sniping), as we specify the default provider in the dex field
      custom: {
        custom_router: '',
        custom_pair: '',
        custom_factory: '',
        mapping: {
          activate: false,
          file_name: '',
        },
      },
    },
    wallets: {
      // This field is not used by any end, but it is required on the Sniping API's DTO
      main_wallet,
      wallets,
    },
    operation: {
      private_tx: data.ordinaryBuy.privateTransaction,
      tx_type: 1, // Default value
      bribe_amount: 0, // Default value
      contract: {
        separate_txs: true, // Combined transactions are not working so far, so we use separate ones
      },
      gas: {
        gas_limit: 1000000, // Default value for the internal tests. Later the limit will be calculated automatically
        // auto_calculate: true, // Instead of gas_limit (not implemented on the backends yet)
        approve: {
          gas: 10, // Remove after the internal tests
          miner_tip: +data.advancedBuy.approvePriority || +defaultPriorities.approvePriority, // 7 is the default value
          auto_calculate: true,
        },
        buy: {
          gas: 10, // Remove after the internal tests
          miner_tip: +data.advancedBuy.buyPriority || +defaultPriorities.buyPriority, // 7 is the default value
          auto_calculate: true,
        },
      },
      pair: {
        routing: {
          mode: 2, // 0 for chain's native tokens; 0 - base mode 2 - auto mode
          path: [], // Not specified, as we only use native tokens so far
        },
        fee: {
          mode: 1, // 1 for the automatic calculation
          path_fees: [], // This field is not used when mode = 1, but the Sniping requires it anyway
        },
      },
      setup: {
        buy_mode,
        buy_amount: +convertScientificNotationNumber(+data.ordinaryBuy.spend, MAX_TRX_DECIMALS), // Amount of tokens a user wants to exchange
        // Amount of tokens a user wants to buy
        buy_tokens:
          buy_mode === EBuyMode.MIN_TOKENS
            ? +data.advancedBuy.minPercentTokenOrFail.replace(/%/g, '')
            : +data.ordinaryBuy.receive,
        supply_percentage:
          buy_mode === EBuyMode.MIN_PERCENT
            ? +data.advancedBuy.minPercentTokenOrFail.replace(/%/g, '')
            : 0,
        rounds_to_buy: 1, // Default value
        slippage: +data.ordinaryBuy.slippage,
        auto_approve: true, // If true, then no need to call an approve endpoint after the buying
        force_buy: true, // Default value
      },
      shields: {
        anti_fake_liquidity:
          !data.ordinaryBuy.degenChadMode &&
          (!!shields.minimum_liquidity || !!shields.maximum_liquidity),
        anti_tax: !data.ordinaryBuy.degenChadMode && !!(shields.buy_tax || shields.sell_tax),
        anti_honeypot: !data.ordinaryBuy.degenChadMode,
        anti_market_cap: !!shields.maximum_market_cap,
        buy_tax: shields.sell_tax && !shields.buy_tax ? 100 : shields.buy_tax,
        sell_tax: shields.buy_tax && !shields.sell_tax ? 100 : shields.sell_tax,
        maximum_liquidity:
          shields.minimum_liquidity && !shields.maximum_liquidity
            ? 1000000000
            : shields.maximum_liquidity,
        minimum_liquidity: shields.minimum_liquidity,
        maximum_market_cap: shields.maximum_market_cap,
        // Default values
        minimum_market_cap: 0,
        anti_honeypot_mode: 0,
        anti_fake_liquidity_mode: 0,
        anti_tax_mode: 0,
        anti_price_impact: false,
        price_impact: 0,
        anti_buy_sell_ratio: false,
        buy_sell_ratio: 0,
      },
    },
  }
}

export { createManualBuyPayload }
