import { FC } from 'react'

import { Tabs, Typography } from '@/libs/common'

import { AddWalletTab } from './libs/components/add-wallet-tab'
// import { ExportTab } from './libs/components/export-tab'
import { WalletsTab } from './libs/components/wallets-tab'
import styles from './styles.module.scss'

const WalletManagement: FC = () => {
  return (
    <div className={styles.content}>
      <Typography variant="h1" className={styles.title}>
        Wallet Management
      </Typography>
      <Tabs
        tabs={[
          { label: 'Wallets', content: <WalletsTab /> },
          { label: 'Add new wallet', content: <AddWalletTab /> },
          // { label: 'Export', content: <ExportTab /> },
        ]}
      />
    </div>
  )
}

export { WalletManagement }
