import { FC } from 'react'

import { ButtonGroupRadio, ButtonGroupRadioButton, Typography } from '@/libs/common'
import { WalletsQuantityButton } from '@/libs/enums'

// import { CustomInput } from './libs/components/custom-input'
import styles from './styles.module.scss'

type TProperty = {
  counter?: number
  handleChange: (newValue: number | string) => void
}

const SelectWalletCount: FC<TProperty> = ({ counter, handleChange }) => {
  return (
    <ButtonGroupRadio
      label={
        <Typography variant="body2" textColor="light-grey">
          Select wallet count
        </Typography>
      }
      value={counter}
      onChange={(_, value) => handleChange(value)}
      exclusive
    >
      {Object.values(WalletsQuantityButton).map(
        (content) => (
          <ButtonGroupRadioButton value={content} className={styles.button} key={content}>
            {content}
          </ButtonGroupRadioButton>
        ),
        // {
        //   return content === WalletsQuantityButton.CUSTOM ? (
        //     <CustomInput key={content} onChange={handleChange} />
        //   ) : (
        //     <ButtonGroupRadioButton value={content} className={styles.button} key={content}>
        //       {content}
        //     </ButtonGroupRadioButton>
        //   )
        // }
      )}
    </ButtonGroupRadio>
  )
}

export { SelectWalletCount }
