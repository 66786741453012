import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { SelectFees } from '@/components/select-fees'
import { SelectWallet } from '@/components/select-wallet'
import { useAppMode } from '@/hooks/useAppMode'
import { useWalletFromParams } from '@/hooks/useWalletFromParams'
import { BackButton, Button, InputWithRadioGroup, Tabs, Typography } from '@/libs/common'
import { AmountDropdown } from '@/libs/common/input/components/amount-dropdown'
import { OptionalInput } from '@/libs/common/optional-input'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppRoute } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { TFees } from '@/libs/types'
import { TSelectOption } from '@/libs/types/select-option.type'
import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const spendOptions: TSelectOption<number>[] = [
  {
    value: 0.05,
    label: '0.05',
  },
  {
    value: 0.1,
    label: '0.1',
  },
  {
    value: 0.25,
    label: '0.25',
  },
  {
    value: 0.5,
    label: '0.5',
  },
]

const defaultValues = {
  amount: '',
  address: '',
  ownWallet: '',
}

const TransferFromWallet: FC = () => {
  const { mode } = useAppMode()
  const navigate = useNavigate()
  const currentWallet = useWalletFromParams()

  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userWallets = useAppSelector((state) => state.user.userWallets)

  const [fees, setFees] = useState<TFees>()
  const [wallet, setWallet] = useState<string[]>([])
  const [isDisabled, setIsDisable] = useState(true)
  const [holding, setHolding] = useState<THoldingsToken | null>(null)
  const [tab, setTab] = useState(0)

  const walletBalance = useMemo(() => {
    let balance = +(currentWallet?.balanceFormatted || 0)
    if (holding) {
      const holdingWallet = holding.w.find(
        (item) => item.a.toLowerCase() === currentWallet?.public_key.toLowerCase(),
      )
      balance = holdingWallet?.balanceFormatted || 0
    }
    return balance
  }, [holding])

  const schema = yup.object({
    amount: stringOfNumbersValidation.required().test((value) => +value <= walletBalance),
    address: yup.string(),
  })

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema) as any,
  })

  const amount = watch('amount')
  const externalWallet = watch('address')
  const ownWallet = watch('ownWallet')

  useEffect(() => {
    const isWalletExist = tab === 0 ? externalWallet.replace(/\s/g, '') : ownWallet.length
    setIsDisable(!isWalletExist || !fees || !isValid || !fees.minerTip)
  }, [tab, fees, externalWallet, ownWallet, isValid])

  useEffect(() => {
    if (amount) trigger('amount')
  }, [walletBalance])

  const calculateWorth = (amount: number) => {
    return holding && holding.balanceFormatted
      ? amount * (+holding.tvu / +holding.balanceFormatted || 0)
      : amount * (currentChain?.nativeTokenPriceInUsd || 0)
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    if (!fees) return

    const payload = {
      type: 'transfer',
      token: holding || null,
      amount: {
        value: data.amount,
        valueUSD: calculateWorth(+data.amount),
      },
      wallets: tab === 0 ? [externalWallet] : [ownWallet],
      minerTip: fees.minerTip,
    }
    navigate(
      `${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.CONFIRM_TRANSFER}/${currentWallet?.public_key}`,
      {
        state: { payload },
      },
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackButton isAbsolute />
        <Typography variant="h1" className={styles.title}>
          Transfer from {currentWallet?.name}
        </Typography>
        <Typography variant="body2" align="center">
          ~ {walletBalance && formatNumber(walletBalance).formatted} {holding ? holding.s : 'ETH'}{' '}
          ($
          {calculateWorth(walletBalance).toFixed(2)})
        </Typography>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.content}>
        <div className={styles.content}>
          <div className={styles.inputWrapper}>
            <Controller
              name="amount"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => {
                return (
                  <InputWithRadioGroup
                    label="Amount"
                    placeholder="Enter amount to transfer"
                    isNumeric
                    className={styles.input}
                    onOptionSelect={(value) => {
                      const formattedValue = Array.isArray(value) ? value[0] : value
                      onChange(formattedValue)
                      trigger('amount')
                    }}
                    endAdornment={
                      <AmountDropdown
                        wallet={currentWallet}
                        onTokenSelect={(value) => {
                          setHolding(value)
                        }}
                      />
                    }
                    radioGroupName="amount"
                    options={spendOptions}
                    onChange={(e) => {
                      if (!currentWallet || !currentChain.nativeTokenPriceInUsd) return
                      const newValue = convertScientificNotationNumber(
                        e.target.value,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      onChange(newValue)
                    }}
                    error={!!errors.amount?.message}
                    {...field}
                  />
                )
              }}
            />
            <Typography variant="body2">${calculateWorth(+amount).toFixed(2)}</Typography>
          </div>
          <Tabs
            value={tab}
            onChange={(_, value) => {
              setTab(value)
            }}
            tabs={[
              {
                label: 'External',
                content: (
                  <div className={styles.tab}>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          className={styles.input}
                          label="Address"
                          placeholder="Enter receiving wallet address"
                          endAdornment={<></>}
                          error={!!errors?.address?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                ),
              },
              {
                label: 'Between your wallets',
                content: (
                  <Controller
                    name="ownWallet"
                    control={control}
                    render={({ field: { ref, value, onChange, ...field } }) => (
                      <SelectWallet
                        wallet={wallet}
                        wallets={userWallets?.filter(
                          (el) => el.public_key !== currentWallet?.public_key,
                        )}
                        onChange={(value) => {
                          setWallet(value)
                          onChange(value)
                        }}
                        isMulti={false}
                        {...field}
                      />
                    )}
                  />
                ),
              },
            ]}
          />
          <SelectFees fees={fees} onChange={(value) => setFees(value)} />
        </div>
        <Button type="submit" disabled={isDisabled} className={styles.button}>
          Continue
        </Button>
      </form>
    </div>
  )
}

export { TransferFromWallet }
