import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type TInitialState = {
  isAppIdle: boolean
  isNavigateAvailable: boolean
}

const initialState: TInitialState = {
  isAppIdle: false,
  isNavigateAvailable: true,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppIdleState: (state, { payload }: PayloadAction<boolean>) => {
      state.isAppIdle = payload
    },
    setIsNavigateAvailable: (state, { payload }: PayloadAction<boolean>) => {
      state.isNavigateAvailable = payload
    },
  },
})

const { setAppIdleState, setIsNavigateAvailable } = appSlice.actions

export { appSlice, setAppIdleState, setIsNavigateAvailable }
