import { useAuth0 } from '@auth0/auth0-react'

import { getAccessTokenStorageKey } from '@/libs/helper/getAccessTokenStorageKey'

export const useAuth = () => {
  const { logout, loginWithRedirect: loginWithRedirectAuth, ...rest } = useAuth0()

  const logoutWithRedirect = (url = window.location.origin) =>
    logout({
      logoutParams: {
        returnTo: url,
      },
    })

  const loginWithRedirect = () => {
    localStorage.removeItem(getAccessTokenStorageKey())
    return loginWithRedirectAuth()
  }

  return {
    ...rest,
    logout,
    logoutWithRedirect,
    loginWithRedirect,
  }
}
