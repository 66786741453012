import { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardActionArea } from '@mui/material'

import { changeUserWalletsOrder } from '@/api/wallet'
import { Accordion, AccordionListElement, ButtonCopy, Typography } from '@/libs/common'
import { DragAndDropList } from '@/libs/common/drag-and-drop-list'
import { AppRoute, WalletAccordionVariant } from '@/libs/enums'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { ValueOf } from '@/libs/types'
import { TUserWallet } from '@/libs/types/user.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserWallets } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

type TProperty = {
  variant: ValueOf<typeof WalletAccordionVariant>
}

const WalletAccordion: FC<TProperty> = ({ variant }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const wallets = useAppSelector((state) => state.user.userWallets)
  const dispatch = useAppDispatch()

  const [isSomeElementDragging, setIsSomeElementDragging] = useState(false)
  const navigate = useNavigate()

  const onSettingClick = () => {
    navigate(`${AppRoute.MODAL}/${AppRoute.WALLET_MANAGEMENT}`)
  }

  const onCardClick = (publicKey: string) => {
    navigate(`${AppRoute.MODAL}/${AppRoute.WALLET_DETAILS}/${publicKey}`)
  }

  const renderItem = useCallback(
    (item: TUserWallet, isDragging: boolean) => {
      return (
        <AccordionListElement disableHover={isSomeElementDragging && !isDragging} enableBurger>
          <CardActionArea
            content="div"
            className={styles.content}
            onClick={() => onCardClick(item.public_key)}
          >
            <div className={styles.walletDesc}>
              <Typography variant="body2" className={styles.walletName}>
                {item.name}
              </Typography>
              <Typography variant="body2" className={styles.walletHash}>
                {hideWalletAddress(item.public_key)} <ButtonCopy value={item.public_key} />
              </Typography>
            </div>
            <div className={styles.walletValues}>
              <div className={styles.walletChainWrapper}>
                <Typography variant="body2" className={styles.walletChainValue}>
                  {formatTokenPrice(item?.balanceFormatted || '0').formatted}
                </Typography>
                <Typography variant="body2" className={styles.walletChain}>
                  {currentChain.description}
                </Typography>
              </div>
              {!!currentChain.nativeTokenPriceInUsd && (
                <Typography variant="body2" className={styles.walletCurrency}>
                  ${(+(item.balanceFormatted || 0) * currentChain.nativeTokenPriceInUsd).toFixed(2)}
                </Typography>
              )}
            </div>
          </CardActionArea>
        </AccordionListElement>
      )
    },
    [isSomeElementDragging, currentChain],
  )

  const handleChangeWalletsOrder = () => {
    if (!wallets) return

    const newWalletsOrder = wallets?.map(({ id: walletId }, newPriority) => ({
      walletId,
      newPriority,
    }))

    changeUserWalletsOrder(newWalletsOrder)
  }

  return (
    <Accordion
      title={variant}
      enableSettings
      onSettingClick={onSettingClick}
      contentWithScroll="wallets"
    >
      {wallets?.length ? (
        <DragAndDropList
          data={wallets}
          propOfItemForKey="id"
          setData={(newOrder) => {
            dispatch(setUserWallets(newOrder))
          }}
          renderItem={renderItem}
          onDragStateChange={(state) => {
            setIsSomeElementDragging(state)
          }}
          onOrderChanged={handleChangeWalletsOrder}
        />
      ) : (
        <Typography variant="body1" component="span" textColor="grey" align="center">
          No wallets
        </Typography>
      )}
    </Accordion>
  )
}

export { WalletAccordion }
