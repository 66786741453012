import { useState } from 'react'

import cls from 'classnames'

import { TrendingTable } from '@/components/token-list/libs/trending-table'
import styles from '@/components/token-list/styles.module.scss'
import { ButtonGroupRadio, ButtonGroupRadioButton, Icon } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { TSelectOption } from '@/libs/types/select-option.type'

const chains = [
  // {
  //   value: 'all',
  //   label: 'All',
  // },
  {
    value: 'eth',
    label: 'ETH',
    image: IconName.ETHEREUM,
  },
]

const dateFormats: TSelectOption<string>[] = [
  {
    value: 'date',
    label: 'Date',
  },
  {
    value: 'age',
    label: 'Age',
  },
]

const resolutionFormats: TSelectOption<string>[] = [
  {
    value: 'resolution_60',
    label: '1H',
  },
  {
    value: 'resolution_240',
    label: '4H',
  },
  {
    value: 'resolution_720',
    label: '12H',
  },
  {
    value: 'resolution_1D',
    label: '24H',
  },
]

const Trending = () => {
  const [chain, setChain] = useState(chains[0].value)
  const [dateFormat, setDateFormat] = useState(dateFormats[0].value)
  const [resolutionFormat, setResolutionFormat] = useState(resolutionFormats[0].value)

  return (
    <div>
      <div className={styles.controls}>
        <div className={styles.chains}>
          <ButtonGroupRadio
            className={styles.group}
            value={chain}
            onChange={(_: React.BaseSyntheticEvent, newValue: string) => setChain(newValue)}
            separated
            exclusive
          >
            {chains.map((option) => (
              <ButtonGroupRadioButton
                key={option.value}
                value={option.value}
                className={cls(styles.button, {
                  [styles.withIcon]: !!option.image,
                })}
              >
                {option.image && <Icon name={option.image} />}
                {option.label}
              </ButtonGroupRadioButton>
            ))}
          </ButtonGroupRadio>
          {/* <Button styleVariant="black" className={cls(styles.button, styles.greyBorder)}>
            <Icon name={IconName.MORE} />
          </Button> */}
        </div>

        <div className={styles.formats}>
          <ButtonGroupRadio
            className={styles.group}
            value={resolutionFormat}
            onChange={(_: React.BaseSyntheticEvent, newValue: string) =>
              setResolutionFormat(newValue)
            }
            exclusive
          >
            {resolutionFormats.map(({ value, label }) => (
              <ButtonGroupRadioButton
                key={value}
                value={value}
                className={cls(styles.button, styles.bigSize, {
                  [styles.active]: resolutionFormat === value,
                })}
              >
                {label}
              </ButtonGroupRadioButton>
            ))}
          </ButtonGroupRadio>

          <ButtonGroupRadio
            className={styles.group}
            value={dateFormat}
            onChange={(_: React.BaseSyntheticEvent, newValue: string) => setDateFormat(newValue)}
            exclusive
          >
            {dateFormats.map(({ value, label }) => (
              <ButtonGroupRadioButton
                key={value}
                value={value}
                className={cls(styles.button, { [styles.active]: dateFormat === value })}
              >
                {label}
              </ButtonGroupRadioButton>
            ))}
          </ButtonGroupRadio>
        </div>
      </div>
      <TrendingTable resolution={resolutionFormat} dateFormat={dateFormat} />
    </div>
  )
}

export { Trending }
