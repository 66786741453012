import { FC, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { renameWallet } from '@/api/wallet'
import { BackButton, Button, Input, Typography } from '@/libs/common'
import { AppMode, AppRoute } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserByIdWithWallets } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

type WalletNameType = {
  walletName: string
}

const walletNameSchema = yup.object({
  walletName: yup
    .string()
    .min(5, 'Wallet name must be at least 5 characters')
    .max(30)
    .required('Enter wallet name'),
})

const defaultValues: WalletNameType = {
  walletName: 'Wallet1121133',
}

const EditWalletName: FC = () => {
  const user = useAppSelector((state) => state.user.userData)
  const wallets = useAppSelector((state) => state.user.userWallets)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const { walletId } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const currentWallet = useMemo(
    () => wallets?.find((item) => item.id === walletId),
    [walletId, wallets],
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { walletName: currentWallet?.name || '' },
    resolver: yupResolver(walletNameSchema),
  })

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!user || !currentWallet) return
    try {
      setIsLoading(true)
      await renameWallet(user._id, currentWallet.public_key, data.walletName.trim())
      await dispatch(fetchUserByIdWithWallets(user._id)).unwrap()
      navigate(
        `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.WALLET_MANAGEMENT}`,
      )
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.container}>
      <BackButton isAbsolute />
      <Typography variant="h1" className={styles.title}>
        Edit Wallet Name
      </Typography>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="walletName"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Input
              error={Boolean(errors.walletName)}
              errorMessage={errors.walletName?.message}
              maxLength={30}
              isTransparent
              label="Wallet name"
              placeholder="Enter wallet name"
              {...field}
            />
          )}
        />

        <Button type="submit" isLoading={isLoading}>
          Save
        </Button>
      </form>
    </div>
  )
}

export { EditWalletName }
