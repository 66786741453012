import { useSniperWebsocket } from '@/hooks/useSniperWebsocket'
import { createQueryString } from '@/libs/helper/createQueryString'
import { TSniperHoldingsResponse } from '@/libs/types/sniper-holdings-socket-response.type'
import { SniperSockerService } from '@/socket'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserHoldingsWebsocket } from '@/store/slices/user.slice'

const holdingsSniperSocket = new SniperSockerService(import.meta.env.VITE_SNIPER_INDEXER_SOCKET_URL)

const useWatchHoldings = () => {
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const dispatch = useAppDispatch()

  useSniperWebsocket({
    socket: holdingsSniperSocket,
    connectionProps: {
      endpoint: 'holdings',
      query: createQueryString({ blockchain: currentChain.indexerChainId }),
      onOpen: () => {
        holdingsSniperSocket.emit(
          JSON.stringify({ subscribe: userWallets?.map((wallet) => wallet.public_key) }),
        )
      },
    },
    verifyExistanceBeforeConnect: [userWallets],
    onMessage: (jsonData) => {
      const data = JSON.parse(jsonData) as TSniperHoldingsResponse
      if (data.subcribed) {
        return
      }
      dispatch(setUserHoldingsWebsocket(data))
    },
  })
}

export { useWatchHoldings }
