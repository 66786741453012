import { FC } from 'react'

import cn from 'classnames'

import { ButtonIcon, type TButtonIconProps } from '@/libs/common/index'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TButtonCopyProps = Omit<TButtonIconProps, 'icon'> & {
  value: string | undefined
}

const ButtonCopy: FC<TButtonCopyProps> = ({ value, className, ...props }) => {
  const handleCopyToClipboard = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (value) navigator.clipboard.writeText(value)
  }

  return (
    <ButtonIcon
      onClick={handleCopyToClipboard}
      className={cn(styles.buttonCopy, className)}
      {...props}
      icon={IconName.COPY}
    />
  )
}

export { ButtonCopy }
