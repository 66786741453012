import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import cn from 'classnames'

import { generateWallets } from '@/api/wallet'
import { TGenerateWalletsResponse } from '@/api/wallet/types'
import { CustomToast } from '@/components/custom-toast'
import { PrivateKeysTable } from '@/components/private-keys-table'
// import { PrivateKeysTable } from '@/components/private-keys-table'
import { SelectWalletCount } from '@/components/select-wallet-count'
import { useAppMode } from '@/hooks/useAppMode'
import { BackButton, Button, Checkbox, Typography } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { handleError } from '@/libs/helper/handleError'
import { useAppDispatch, useAppSelector } from '@/store'
import {
  clearUserWalletsUnconfirmed,
  fetchUserByIdWithWallets,
  setUserWalletsUnconfirmed,
} from '@/store/slices/user.slice'

import styles from './styles.module.scss'

type TProps = {
  duringSignUp?: boolean
}

const GenerateNewWallet: FC<TProps> = ({ duringSignUp = false }) => {
  const { mode } = useAppMode()
  const userData = useAppSelector((state) => state.user.userData)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const [counter, setCounter] = useState<number>()
  const [generatedWallets, setGeneratedWallets] = useState<TGenerateWalletsResponse[] | null>(null)
  const [check, setCheck] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const onChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheck(event.target.checked)
  }

  const handleChange = (newValue: number | string) => {
    if (newValue) {
      setCounter(+newValue)
      return
    }
    if (newValue !== undefined) setCounter(0)
  }

  const onGenerateWallets = async () => {
    if (!userData || !counter) return
    try {
      setIsLoading(true)
      const { data } = await generateWallets({
        userId: userData._id,
        amount: counter,
        isBackup: false,
      })
      setGeneratedWallets(data)
      dispatch(setUserWalletsUnconfirmed(data))
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onAddWallets = async () => {
    if (!userData) return
    try {
      setIsLoading(true)
      if (!duringSignUp) {
        await dispatch(fetchUserByIdWithWallets(userData._id)).unwrap()
      }
      CustomToast('success', 'Your wallets were successfully generated')
      dispatch(clearUserWalletsUnconfirmed())
      navigate(duringSignUp ? AppRoute.CREATE_MASTER_PASSWORD : `${AppRoute.DASHBOARD}/${mode}`, {
        replace: duringSignUp,
      })
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.container}>
      {!duringSignUp && <BackButton isAbsolute />}
      <Typography
        variant="h1"
        align="center"
        className={cn({ [styles.title]: duringSignUp !== undefined && !duringSignUp })}
      >
        Generate new wallet(s)
      </Typography>

      <Typography variant="body1" align="center" className={styles.explanationWrapper}>
        Just choose the number of wallets you want and the bot will create them automatically. You
        will get their private keys right after generation.
      </Typography>

      <SelectWalletCount counter={counter} handleChange={handleChange} />
      {!!generatedWallets?.length && (
        <>
          <div className={styles.tableWrapper}>
            <PrivateKeysTable wallets={generatedWallets} />
            {/* <Button styleVariant="black">Export wallets file</Button> */}
          </div>
        </>
      )}
      {!!generatedWallets?.length && (
        <div className={styles.checkboxWrapper}>
          <label className={styles.label} htmlFor="agreements">
            <Checkbox whiteLabel value={check} onChange={onChangeCheckBox} id="agreements" />

            <Typography variant="body2">
              I have securely stored the private keys for the wallets and acknowledge that I will
              not have the ability to retrieve them if I forget my encryption password.
            </Typography>
          </label>
        </div>
      )}
      {generatedWallets?.length ? (
        <Button disabled={!check} onClick={onAddWallets} isLoading={isLoading}>
          Add wallets
        </Button>
      ) : (
        <Button disabled={!counter} onClick={onGenerateWallets} isLoading={isLoading}>
          Generate wallets
        </Button>
      )}
    </div>
  )
}

export { GenerateNewWallet }
