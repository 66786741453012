import { FC } from 'react'

import { DepositTab } from '@/components/deposit-tab'
import { useWalletFromParams } from '@/hooks/useWalletFromParams'
import { BackButton, Typography } from '@/libs/common'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const DepositToWallet: FC = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const wallet = useWalletFromParams()

  return (
    <div>
      <BackButton isAbsolute />
      <Typography variant="h1" className={styles.title}>
        Deposit to {wallet?.name}
      </Typography>
      {!!wallet && (
        <Typography variant="body1" align="center" marginBottom={3}>
          {formatTokenPrice(wallet.balanceFormatted || '0').formatted} {currentChain.description}{' '}
          (~$
          {convertScientificNotationNumber(
            +(wallet.balanceFormatted || 0) * (currentChain.nativeTokenPriceInUsd || 0),
            2,
          )}
          )
        </Typography>
      )}

      <DepositTab wallet={wallet} />
    </div>
  )
}

export { DepositToWallet }
