import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Grid } from '@mui/material'

import { useAppMode } from '@/hooks/useAppMode'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Button, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { AppRoute, IconName, LocalStorageItem } from '@/libs/enums'
import { getPrioritiesDefault } from '@/libs/helper/getPrioritiesDefault'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const defaultValues = getPrioritiesDefault()

const SetupPriorities = () => {
  const { mode } = useAppMode()
  const tokenAddress = useAppSelector((state) => state.chain.currentToken?.token.address)
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  })

  const { isBottomTabsVisible } = useWindowDimensions()

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    localStorage.setItem(LocalStorageItem.PRIORITIES, JSON.stringify(data))
    navigate(
      `${AppRoute.DASHBOARD}/${mode}${isBottomTabsVisible ? `/${AppRoute.MODAL}/${AppRoute.BUY_MODAL}` : ''}${tokenAddress ? `?token=${tokenAddress}` : ''}`,
    )
  }

  return (
    <Grid spacing={3} gap={2}>
      <Typography variant="h1" align="center">
        Setup Default Priorities
      </Typography>

      <Typography variant="body2" align="center">
        Explanation...
      </Typography>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" gap={2}>
          <Grid container rowGap={2}>
            <Controller
              name="buyPriority"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  className={styles.input}
                  label="Default Buy Priority"
                  placeholder="Enter gwei amount"
                  isNumeric
                  tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
                  endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                  error={!!errors.buyPriority?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="sellPriority"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  className={styles.input}
                  label="Default Sell Priority"
                  placeholder="Enter gwei amount"
                  isNumeric
                  tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                  endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                  error={!!errors.sellPriority?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="approvePriority"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  className={styles.input}
                  label="Default Approve priority"
                  placeholder="Enter gwei amount"
                  isNumeric
                  tooltipInfo="An extra fee that can be added to speed up the new token approve for you wallet. 1 GWEI = 0.000000001 ETH."
                  endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                  error={!!errors.approvePriority?.message}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>

        <Button type="submit" className={styles.button}>
          Save
        </Button>
      </form>
    </Grid>
  )
}

export { SetupPriorities }
