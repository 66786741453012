import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Grid, TableCell, TableRow } from '@mui/material'
import cn from 'classnames'

import { TUserFavorite } from '@/api/favorites/types'
import styles from '@/components/favorites-table/styles.module.scss'
import { ButtonIcon, TokenPlaceholder, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { useAppDispatch } from '@/store'
import { fetchNewCurrentToken } from '@/store/slices/chain.slice'
import { removeFavoriteToken } from '@/store/slices/favorites-tokens.slice'
import { fetchUserWalletsWithTokensForce } from '@/store/slices/user.slice'

type TProperty = {
  row: TUserFavorite
}

const Row: FC<TProperty> = ({ row }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleRemoveFavoriteToken = () => {
    dispatch(removeFavoriteToken(row.address))
  }

  const handleChangeCurrentToken = () => {
    dispatch(fetchNewCurrentToken(row.address))
    dispatch(fetchUserWalletsWithTokensForce(row.address))
    navigate(`${AppRoute.DASHBOARD}/${AppMode.LITE}?&token=${row.address}`)
  }

  return (
    <TableRow className={styles.row} onClick={handleChangeCurrentToken}>
      <TableCell className={styles.cell}>
        <div className={styles.controls}>
          <ButtonIcon
            icon={IconName.CROSSED_STAR}
            onClick={(e) => {
              e.stopPropagation()
              handleRemoveFavoriteToken()
            }}
          />

          <div className={styles.iconWrapper}>
            {row.tokenImg ? (
              <img src={row.tokenImg} className={styles.icon} loading="lazy" alt={row.name} />
            ) : (
              <TokenPlaceholder token={row} size="35px" />
            )}

            <img src={row.pairImg} loading="lazy" alt="network logo" className={styles.network} />
          </div>

          <Grid container flexDirection="column">
            <Typography variant="body2" className={styles.symbol}>
              {row.symbol}
            </Typography>

            <Typography variant="body2" className={styles.name}>
              {row.name}
            </Typography>
          </Grid>
        </div>
      </TableCell>
      <TableCell className={styles.cell}>
        <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
          {formatNumber(row.priceUSD).formatted}
        </Typography>
      </TableCell>
      <TableCell className={styles.cell}>
        <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
          {/* ${formatNumber(row.simulation.data.additional_data.volume).formatted} */} -
        </Typography>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.changesColumn)}>
        <div className={styles.valueWrapper}>
          <Typography
            variant="body2"
            textColor="color-white-3"
            className={cn(styles.cellText, Number(row.change24) > 0 ? styles.profit : styles.loss)}
          >
            {row.change24}%
          </Typography>
        </div>
      </TableCell>
      <TableCell className={cn(styles.cell)}>
        <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
          ${formatNumber(row.simulation?.data?.liquidity.pair.value_usd).formatted}
        </Typography>
      </TableCell>
      <TableCell className={cn(styles.cell)}>
        <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
          {formatNumber(row.simulation?.data?.market_cap).raw}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export { Row }
