import { FC } from 'react'

import Grid, { GridProps } from '@mui/material/Grid'
import cn from 'classnames'

import { VerificationStatusBar } from '@/components/verification-status-bar'
import { BackButton } from '@/libs/common'
import { Logo } from '@/libs/common/logo'
import { VerificationStatus } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import { HeaderButtons } from './libs/components'
import styles from './styles.module.scss'

type TAuthCardProps = GridProps & {
  isHeaderButtons?: boolean
  isBackButton?: boolean
  verificationStatus?: ValueOf<typeof VerificationStatus>
  logoClassName?: string
  footerContent?: React.ReactNode
}

const AuthCard: FC<TAuthCardProps> = ({
  children,
  isHeaderButtons,
  isBackButton,
  verificationStatus,
  className,
  logoClassName,
  footerContent,
  ...props
}) => {
  return (
    <Grid container flexDirection={'column'} className={cn(styles.container, className)} {...props}>
      <Grid container className={styles.header}>
        {isHeaderButtons && <HeaderButtons />}
        <Logo className={cn(styles.headerLogo, logoClassName)} size="big" />
      </Grid>
      <Grid container className={styles.content}>
        {verificationStatus && (
          <VerificationStatusBar
            className={styles.verificationStatusBar}
            status={verificationStatus}
          />
        )}
        <Grid item className={styles.childrenContent}>
          {isBackButton && (
            <div className={styles.backButton}>
              <BackButton />
            </div>
          )}
          {children}
        </Grid>
      </Grid>
      {footerContent && (
        <Grid container className={styles.footerContent}>
          {footerContent}
        </Grid>
      )}
    </Grid>
  )
}

export { AuthCard, type TAuthCardProps }
