import { useState } from 'react'

import cls from 'classnames'

import { NewTokensTable } from '@/components/token-list/libs/new-tokens-table'
import styles from '@/components/token-list/styles.module.scss'
import { Button, ButtonGroupRadio, ButtonGroupRadioButton, Icon } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { TSelectOption } from '@/libs/types/select-option.type'

const chains = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'eth',
    label: 'ETH',
    image: IconName.ETHEREUM,
  },
]

const resolutionFormats: TSelectOption<string>[] = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'DEPLOYED',
    label: 'Deployed',
  },
  {
    value: 'TRADING',
    label: 'Trading',
  },
  {
    value: 'RUGGED',
    label: 'Rugged',
  },
]

const NewTokens = () => {
  const [chain, setChain] = useState(chains[0].value)
  const [resolutionFormat, setResolutionFormat] = useState(resolutionFormats[0].value)

  return (
    <div>
      <div className={styles.controls}>
        <div className={styles.chains}>
          <ButtonGroupRadio
            className={styles.group}
            value={chain}
            onChange={(_: React.BaseSyntheticEvent, newValue: string) => setChain(newValue)}
            separated
            exclusive
          >
            {chains.map((option) => (
              <ButtonGroupRadioButton
                key={option.value}
                value={option.value}
                className={cls(styles.button, {
                  [styles.withIcon]: !!option.image,
                })}
              >
                {option.image && <Icon name={option.image} />}
                {option.label}
              </ButtonGroupRadioButton>
            ))}
          </ButtonGroupRadio>
          <Button styleVariant="black" className={cls(styles.button, styles.greyBorder)}>
            <Icon name={IconName.MORE} />
          </Button>
        </div>

        <div className={styles.formats}>
          <ButtonGroupRadio
            className={styles.group}
            value={resolutionFormat}
            onChange={(_: React.BaseSyntheticEvent, newValue: string) =>
              setResolutionFormat(newValue)
            }
            exclusive
          >
            {resolutionFormats.map(({ value, label }) => (
              <ButtonGroupRadioButton
                key={value}
                value={value}
                className={cls(styles.button, styles.bigSize, {
                  [styles.active]: resolutionFormat === value,
                })}
              >
                {label}
              </ButtonGroupRadioButton>
            ))}
          </ButtonGroupRadio>
        </div>
      </div>
      <NewTokensTable resolution={resolutionFormat} />
    </div>
  )
}

export { NewTokens }
