import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import cn from 'classnames'

import { makeManualBuy } from '@/api/orders'
import { BuyTemplateSettingsList } from '@/components/buy-template-settings-list'
import { CustomToast } from '@/components/custom-toast'
import { SelectWallet } from '@/components/select-wallet'
import { TemplatesControl } from '@/components/templates-control'
import { useUpdatingStore } from '@/hooks/updateStore.hook'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import {
  Accordion,
  Button, // ButtonGroupRadio,
  // ButtonGroupRadioButton,
  // HiddenInputs,
  Icon,
  Input,
  InputWithBuyAmount,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppMode, AppRoute, IconName, TransactionResponseType } from '@/libs/enums'
import { getBuyAmountOptions } from '@/libs/helper'
import { createBuyFormStateFromTemplate } from '@/libs/helper/buy/createBuyFormStateFromTemplate'
import { createManualBuyPayload } from '@/libs/helper/buy/createManualBuyPayload'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { handleError } from '@/libs/helper/handleError'
import { processTransactionResponse } from '@/libs/helper/processTransactionResponse'
import { validateRequiredFields } from '@/libs/helper/validateRequiredFields'
import { TBuyTemplate } from '@/libs/types/template'
import { TWallet } from '@/libs/types/transaction'
import { getManualBuyValidationSchema } from '@/libs/validations/buy'
import { useAppDispatch, useAppSelector } from '@/store'
import { clearCurrentSelectedTemplate } from '@/store/slices/user.slice'

import { getDefaultTeplate } from './libs/utils/get-default-template'
import styles from './styles.module.scss'

type TBuyPayload = Omit<TBuyTemplate, '_id' | '__v' | 'name'> | undefined

type TProps = {
  usedInModal?: boolean
}

enum ELastUsedAmountInput {
  SPEND = 'Spend',
  RECEIVE = 'Receive',
}

const manualTemplate = {
  _id: 'Manual',
  name: 'Manual',
  isPredefined: true,
}

// const limitOptions: TSelectOption<number>[] = [
//   {
//     value: -10,
//     label: '-10%',
//   },
//   {
//     value: -20,
//     label: '-20%',
//   },
//   {
//     value: -30,
//     label: '-30%',
//   },
//   {
//     value: -40,
//     label: '-40%',
//   },
// ]
const spendOptions = getBuyAmountOptions('ordinaryBuy.spend', ['0.1', '0.2', '0.5', '1'])

// const ProfitSettingsTabs: TSelectOption<string>[] = [
//   { value: 'sellInSteps', label: 'Sell in steps' },
//   { value: 'sellOnProfit', label: 'Sell on profit' },
//   { value: 'trailingSell', label: 'Trailing sell' },
// ]

const advancedBuyDefaultValues = {
  buyPriority: '',
  approvePriority: '',
  minPercentTokenOrFail: '',
  maxTxOrFail: false,
}

const shieldsDefaultValues = {
  buy_tax: '',
  sell_tax: '',
  minimum_liquidity: '',
  maximum_liquidity: '',
  maximum_market_cap: '',
}

// All the commented fields will be used once the full version of the form is ready
const BuyTabForm: FC<TProps> = ({ usedInModal = false }) => {
  const dispatch = useAppDispatch()
  const { updateStoreAfterBuySell } = useUpdatingStore()
  const userData = useAppSelector((state) => state.user.userData)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const userTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const userCurrentSelectedTemplate = useAppSelector(
    (state) => state.user.userCurrentSelectedTemplate,
  )
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const currentDex = currentToken?.tokenDexes[0]

  const [isLoading, setIsLoading] = useState(false)
  const [isSettingsExpanded, setIsSettingsExpanded] = useState(false)
  const [lastUsedAmountInput, setLastUsedAmountInput] = useState<ELastUsedAmountInput | null>(null)

  const templates = useMemo(() => {
    const defaultTemplates = [manualTemplate]
    const defaultCustomTemplate = getDefaultTeplate()
    if (defaultCustomTemplate) {
      defaultTemplates.push(defaultCustomTemplate)
    }
    return [
      ...defaultTemplates,
      ...(userTemplates?.filter(
        (item) => item.network.blockchain === currentChain.indexerChainId,
      ) || []),
    ]
  }, [userTemplates, currentChain])

  const [currentTemplate, setCurrentTemplate] = useState<
    (TBuyTemplate & { isPredefined?: boolean }) | null
  >((templates as any)[1]?.isPredefined ? (templates[1] as any) : null)
  // const [footerTab, setFooterTab] = useState<string>('market')
  // const [profitTab, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)
  // const [isAdvancedSettings, setIsAdvancedSetting] = useState(false)
  // const [isAutoSellSettings, setIsAutoSellSetting] = useState(false)
  // const [isAntiRugSettings, setIsAntiRugSettings] = useState(defaultValues.antiRug)
  // const [walletsOnProfit, setWalletsOnProfit] = useState<number[]>(
  //   defaultValues.walletsToSellOnProfit,
  // )
  const [availableTokens, setAvailableTokens] = useState(0)
  const [isDisabled, setIsDisabled] = useState(true)

  const ordinaryBuyDefaultValues = useMemo(() => {
    const defaultWallet = !userWallets ? null : userWallets.find((wallet) => wallet.isDefault)

    if (defaultWallet) {
      setAvailableTokens(+(defaultWallet.balanceFormatted || 0))
    }

    return {
      privateTransaction: true,
      degenChadMode: false,
      slippage: 50,
      // buyPriority: '',
      selectedWallets: defaultWallet ? [defaultWallet.public_key] : [],
      // onLimit: {
      //   dip: '',
      //   marketcap: '',
      //   price: '',
      //   triggerPricePercent: '',
      //   expiration: '',
      // },
      spend: '',
      receive: '',
    }
  }, [])

  const defaultValues = {
    ordinaryBuy: ordinaryBuyDefaultValues,
    advancedBuy: advancedBuyDefaultValues,
    shields: shieldsDefaultValues,

    // transferOnBlacklist: false,
    // antiBlacklist: false,
    // antiRug: false,
    // antiRugThreshold: 0,
    // sellRugExtraTip: '12',
    // autoSell: false,
    // sellPriority: '',
    // walletsToSellOnProfit: [],
    // walletsToSellOnLoss: [],
    // stopLoss: '',
    // stopLossAmount: '',
    // sellOnProfit: '',
    // sellOnProfitAmount: '',
    // walletsToTrailingSell: [],
    // trailingSell: '',
  }

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(getManualBuyValidationSchema(availableTokens)) as any,
  })

  const minimumReceive =
    +watch('ordinaryBuy.receive') -
    ((currentTemplate ? currentTemplate.operation.setup.slippage : +watch('ordinaryBuy.slippage')) /
      100) *
      +watch('ordinaryBuy.receive')

  const fieldsRequiredForBuyActions = watch(['ordinaryBuy.slippage', 'ordinaryBuy.selectedWallets'])

  // TODO: Move this logic with 2 inputs to a separate component
  useEffect(() => {
    if (!currentChain.nativeTokenPriceInUsd || !lastUsedAmountInput) return

    if (lastUsedAmountInput === ELastUsedAmountInput.SPEND) {
      const spendValue = getValues().ordinaryBuy.spend
      if (spendValue) {
        calculateReceive(spendValue)
      }
    } else if (lastUsedAmountInput === ELastUsedAmountInput.RECEIVE) {
      const receiveValue = getValues().ordinaryBuy.receive
      if (receiveValue) {
        calculateSpend(receiveValue)
      }
    }
  }, [currentChain])

  useEffect(() => {
    if (currentTemplate) {
      setIsDisabled(false)
      return
    }
    setIsDisabled(validateRequiredFields(fieldsRequiredForBuyActions))
  }, [fieldsRequiredForBuyActions, currentTemplate])

  useEffect(() => {
    if (currentTemplate) {
      const newTemplate = templates.find((item) => item._id === currentTemplate._id)
      handleTemplateChange(newTemplate?._id)
    }
  }, [templates])

  useEffect(() => {
    setAvailableTokens(handleAvailableTokens(currentTemplate))
  }, [userWallets])

  const handleAvailableTokens = (customTemplate?: TBuyTemplate | null) => {
    if (!userWallets) return 0

    let newAvailable = 0
    const templateToUse = customTemplate === null ? null : customTemplate || currentTemplate

    if (templateToUse) {
      templateToUse.wallets.wallets.forEach((templateWallet) => {
        const wallet = userWallets.find(
          (userWallet) => userWallet.public_key === templateWallet.public_key,
        )
        newAvailable += +(wallet?.balanceFormatted || 0)
      })
    } else {
      getValues().ordinaryBuy.selectedWallets.forEach((public_key) => {
        newAvailable += +(
          userWallets.find((item) => item.public_key === public_key)?.balanceFormatted || 0
        )
      })
    }

    return newAvailable
  }

  const handleTemplateChange = (newId?: string) => {
    const foundTemplate = templates.find((item) => item._id === newId)
    const newTemplate =
      foundTemplate && foundTemplate._id !== manualTemplate._id
        ? (foundTemplate as TBuyTemplate)
        : null
    setCurrentTemplate(newTemplate)

    setAvailableTokens(handleAvailableTokens(newTemplate))
  }

  const customizeTemplate = () => {
    if (!currentTemplate) return
    const newValues = createBuyFormStateFromTemplate(currentTemplate).template
    Object.keys(newValues).forEach((key: any) => {
      setValue(key, (newValues as any)[key])
    })
    handleTemplateChange(manualTemplate._id)
  }

  // const handleProfitTabChange = useCallback((_: React.BaseSyntheticEvent, newValue: string) => {
  //   setProfitTab(newValue)
  // }, [])

  // const handleFooterTabChange = useCallback((_: React.BaseSyntheticEvent, newValue: string) => {
  //   setFooterTab(newValue)
  // }, [])

  const calculateSpend = (value: string) => {
    if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
    setValue('ordinaryBuy.receive', `${value}`, {
      shouldValidate: true,
    })
    const coeff = +currentToken?.priceUSD / currentChain.nativeTokenPriceInUsd
    setValue(
      'ordinaryBuy.spend',
      +value ? convertScientificNotationNumber(+value * coeff, MAX_TRX_DECIMALS) : '',
      {
        shouldValidate: true,
      },
    )
  }

  const calculateReceive = (value: string) => {
    if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
    setValue('ordinaryBuy.spend', `${value}`, {
      shouldValidate: true,
    })
    const coeff = currentChain.nativeTokenPriceInUsd / +currentToken?.priceUSD
    setValue(
      'ordinaryBuy.receive',
      +value ? convertScientificNotationNumber(+value * coeff, MAX_TRX_DECIMALS) : '',
      {
        shouldValidate: true,
      },
    )
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!userWallets || !userData || !currentDex) return

    try {
      setIsLoading(true)

      let payload: TBuyPayload

      if (!currentTemplate) {
        const wallets: TWallet[] = []
        data.ordinaryBuy.selectedWallets.forEach((publicKey) => {
          const wallet = userWallets.find((item) => item.public_key === publicKey)

          if (wallet) {
            wallets.push({
              name: wallet.name,
              public_key: wallet.public_key,
            })
          }
        })

        payload = createManualBuyPayload({
          data,
          wallets,
          main_wallet: {
            name: 'dummy_wallet',
            public_key: '0x14dC79964da2C08b23698B3D3cc7Ca32193d9955',
          },
        }) as TBuyPayload
      } else {
        const { _id, name, __v, ...templateToUse } = currentTemplate
        payload = { ...templateToUse }
        payload.token = currentToken.token.address
        payload.network = { ...payload.network, dex: currentDex.id }
        payload.operation = {
          ...payload.operation,
          setup: {
            ...payload.operation.setup,
            buy_amount: +convertScientificNotationNumber(+data.ordinaryBuy.spend, MAX_TRX_DECIMALS),
          },
        }
      }

      if (!payload) {
        CustomToast('error', 'Something went wrong')
        return
      }
      const response = await makeManualBuy(payload)
      await updateStoreAfterBuySell()
      processTransactionResponse(response.data.buyResponse, TransactionResponseType.BUY_OR_SELL)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (userCurrentSelectedTemplate.id) {
      const template = userTemplates?.find(({ _id }) => _id === userCurrentSelectedTemplate.id)
      if (template) setCurrentTemplate(template)
      setIsSettingsExpanded(true)
    }
  }, [userCurrentSelectedTemplate])

  const windowDimension = useWindowDimensions()
  const isResponsive = windowDimension.width <= 630

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={cn(styles.container, { [styles.usedInModal]: usedInModal })}>
        <div className={styles.content}>
          <Grid>
            <TemplatesControl
              templates={templates}
              currentTemplateId={currentTemplate?._id || manualTemplate._id}
              onSelectTemplate={handleTemplateChange}
              onCustomize={customizeTemplate}
              customizeDisabled={!currentTemplate}
              editDisabled={!currentTemplate || currentTemplate.isPredefined}
              deleteDisabled={!currentTemplate || currentTemplate.isPredefined}
              isResponsive={isResponsive}
            />
          </Grid>
          <Link
            to={`${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.SETUP_PRIORITIES}`}
            className={styles.link}
          >
            <Typography variant="body2">
              Setup Default Priorities <Icon name={IconName.SETTING_2} />
            </Typography>
          </Link>

          {!currentTemplate && (
            <>
              <Accordion
                className={styles.accordion}
                titleClassName={styles.accordionTitle}
                title="Advanced Manual Buy Settings"
                withDivider
                mainColorArrow
                // onChange={setIsAdvancedSetting}
              >
                <Grid container rowGap={3} flexDirection="column" marginTop={1} marginBottom={3}>
                  <Grid container rowGap={2}>
                    <Typography variant="body2" textColor="white">
                      Buy
                    </Typography>

                    <Controller
                      name="advancedBuy.approvePriority"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          className={styles.input}
                          label="Approve priority"
                          placeholder="Enter gwei amount"
                          isNumeric
                          tooltipInfo="An extra fee that can be added to speed up the new token approve for you wallet. 1 GWEI = 0.000000001 ETH."
                          endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                          error={!!errors.advancedBuy?.approvePriority?.message}
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="advancedBuy.minPercentTokenOrFail"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          className={styles.input}
                          label="Min % / Tokens or fail"
                          placeholder="Enter token amount or %"
                          isNumeric
                          tooltipInfo="Minimal percentage of total supply or exact amount in ETH equivalent of tokens that should be bought or the transaction will fail."
                          endAdornment={<></>}
                          disabled={watch('advancedBuy.maxTxOrFail')}
                          error={!!errors.advancedBuy?.minPercentTokenOrFail?.message}
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="advancedBuy.maxTxOrFail"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <SwitchInline
                          label="Max Tx or fail"
                          tooltipInfo="Will try to buy maximum available amount of tokens in one transaction. The transaction will fail if this cannot be done."
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid container rowGap={2} flexDirection="column">
                    <Typography variant="body2" textColor="white">
                      Safety
                    </Typography>

                    {/* <Grid container columnGap={3} flexWrap="nowrap">
                      <Controller
                        name="transferOnBlacklist"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label="Transfer On Blacklist"
                            tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="antiBlacklist"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label="Anti-Blacklist"
                            tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event."
                            {...field}
                          />
                        )}
                      />
                    </Grid>

                    <HiddenInputs
                      expanded={isAntiRugSettings}
                      trigger={
                        <Controller
                          name="antiRug"
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <SwitchInline
                              label="Anti-Rug"
                              tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                              onChange={(
                                _: React.ChangeEvent<HTMLInputElement>,
                                checked: boolean,
                              ) => {
                                setIsAntiRugSettings(checked)
                                onChange(checked)
                              }}
                              {...field}
                            />
                          )}
                        />
                      }
                    >
                      <Grid container rowGap={2}>
                        <Controller
                          name="antiRugThreshold"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <PercentsInput
                              label="Anti-Rug Threshold"
                              onOptionSelect={(value) => field.onChange(value)}
                              tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="sellRugExtraTip"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder="Amount"
                              isNumeric
                              isNumeric
                              label="Sell Rug Extra Tip"
                              tooltipInfo="The amount of Gwei that will be added over developer transaction to prioritize your transaction."
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </HiddenInputs> */}

                    <Grid
                      container
                      flexWrap="nowrap"
                      gap={3}
                      flexDirection={isResponsive ? 'column' : 'row'}
                    >
                      <Controller
                        name="shields.buy_tax"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter percentage"
                            isNumeric
                            label="Max Buy Tax"
                            adornmentText="%"
                            tooltipInfo="The percentage of tokens you will lose when buying. The amount of tax is specified by the developer in the smart contract. If the buy tax exceeds entered value, the transaction will fail."
                            error={!!errors.shields?.buy_tax?.message}
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="shields.sell_tax"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter percentage"
                            isNumeric
                            label="Max Sell Tax"
                            adornmentText="%"
                            tooltipInfo="Maximum allowable tax percentage when selling a token. If the value is exceeded, the transaction will fail."
                            error={!!errors.shields?.sell_tax?.message}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      container
                      flexWrap="nowrap"
                      gap={3}
                      flexDirection={isResponsive ? 'column' : 'row'}
                    >
                      <Controller
                        name="shields.minimum_liquidity"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter min liquidity amount"
                            isNumeric
                            label="Min Liquidity"
                            adornmentText="$"
                            tooltipInfo="The minimum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is below the specified value, the transaction will fail."
                            error={!!errors.shields?.minimum_liquidity?.message}
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="shields.maximum_liquidity"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter max liquidity amount"
                            isNumeric
                            label="Max Liquidity"
                            adornmentText="$"
                            tooltipInfo="The maximum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is higher the specified value, the transaction will fail."
                            error={!!errors.shields?.maximum_liquidity?.message}
                            {...field}
                          />
                        )}
                      />
                    </Grid>

                    <Controller
                      name="shields.maximum_market_cap"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          placeholder="Enter max marketcap"
                          isNumeric
                          label="Max Marketcap"
                          adornmentText="$"
                          tooltipInfo="The upper limit of the token's market capitalisation. The transaction will fail if the market cap exceeds the specified value at the moment of its processing."
                          error={!!errors.shields?.maximum_market_cap?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid>

                  {/* <Grid container rowGap={2} flexDirection="column">
                    <Typography variant="body2" textColor="light-grey">
                      Sell
                    </Typography>
                    <HiddenInputs
                      expanded={isAutoSellSettings}
                      trigger={
                        <Controller
                          name="autoSell"
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <SwitchInline
                              label="Auto Sell"
                              tooltipInfo="The bot will automatically sell tokens when the settings you specified are triggered."
                              {...field}
                              onChange={(
                                _: React.ChangeEvent<HTMLInputElement>,
                                checked: boolean,
                              ) => {
                                setIsAutoSellSetting(checked)
                                onChange(checked)
                              }}
                            />
                          )}
                        />
                      }
                    >
                      <Grid container rowGap={2} flexDirection="column">
                        <Controller
                          name="sellPriority"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder="Enter gwei amount"
isNumeric
                              label="Sell Priority"
                              tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                              endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                              onAutoChange={() => {
                                //TO DO: added auto switch
                              }}
                              {...field}
                            />
                          )}
                        />
                        <div className={styles.dividerContainer}>
                          <Typography variant="body2" textColor="grey">
                            Profit
                          </Typography>
                          <div className={styles.divider}></div>
                        </div>

                        <Controller
                          name="walletsToSellOnProfit"
                          control={control}
                          render={({ field: { ref, value, onChange, ...field } }) => (
                            <SelectWallet
                              tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
                              label="Wallets to sell on Profit"
                              isMulti
                              wallet={value}
                              wallets={userWallets}
                              onChange={(value) => {
                                setWalletsOnProfit(value)
                                onChange(value)
                              }}
                              {...field}
                            />
                          )}
                        />

                        <ButtonGroupRadio
                          onChange={handleProfitTabChange}
                          value={profitTab}
                          exclusive
                          className={styles.group}
                        >
                          {ProfitSettingsTabs.map((tab) => (
                            <ButtonGroupRadioButton
                              value={tab.value}
                              key={tab.value}
                              className={cn({ [styles.bgGrey]: tab.value === profitTab })}
                            >
                              {tab.label}
                            </ButtonGroupRadioButton>
                          ))}
                        </ButtonGroupRadio>

                        {profitTab === ProfitSettingsTabs[0].value && (
                          <div>
                            <div className={styles.labelContent}>
                              <InputLabel className={styles.label}>
                                <Typography variant="body2" textColor="light-grey">
                                  Sell in steps
                                </Typography>
                              </InputLabel>
                              <TooltipIcon info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%." marginLeft={5} />
                            </div>
                            {walletsOnProfit.length !== 0 ? (
                              <div className={styles.stepWrapper}>
                                {walletsOnProfit.map((wallet, index) => (
                                  <Controller
                                    key={wallet}
                                    name={`w${wallet}` as keyof typeof defaultValues}
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                      <StepInput
                                        wallet="#1"
                                        balance="12.58 ETH"
                                        className={cn({
                                          [styles.firstInput]: index === 0,
                                          [styles.lastInput]: index === walletsOnProfit.length - 1,
                                        })}
                                        {...field}
                                      />
                                    )}
                                  />
                                ))}
                              </div>
                            ) : (
                              <Grid marginTop={1}>
                                <Typography variant="body1">No wallets selected</Typography>
                              </Grid>
                            )}
                          </div>
                        )}
                        {profitTab === ProfitSettingsTabs[1].value && (
                          <Grid container flexWrap="nowrap" columnGap={3}>
                            <Controller
                              name="sellOnProfit"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter sell on profit"
                                  isNumeric
                                  label="Sell on Profit"
                                  adornmentText="%"
                                  tooltipInfo="Threshold of profit on token position. Sells when the position reaches the specified profit amount."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="sellOnProfitAmount"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter sell profit amount"
                                  isNumeric
                                  label="Sell Profit Amount"
                                  adornmentText="%"
                                  tooltipInfo="The percentage of tokens that will be sold when the Take Profit is triggered."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {profitTab === ProfitSettingsTabs[2].value ? (
                          <>
                            <Controller
                              name="walletsToTrailingSell"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <SelectWallet
                                  isMulti
                                  wallet={value}
                                  label="Wallets to trailing sell"
                                  tooltipInfo="Select which wallets will be used to purchase token."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="trailingSell"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter trailing sell"
                                  isNumeric
                                  label="Trailing Sell"
                                  adornmentText="%"
                                  tooltipInfo="tooltipInfo"
                                  {...field}
                                />
                              )}
                            />
                          </>
                        ) : (
                          <>
                            <div className={styles.dividerContainer}>
                              <Typography variant="body2" textColor="grey">
                                Loss
                              </Typography>
                              <div className={styles.divider}></div>
                            </div>

                            <Controller
                              name="walletsToSellOnLoss"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <SelectWallet
                                  label="Wallets to sell on Loss"
                                  tooltipInfo="Select which wallets will be used to purchase token."
                                  isMulti
                                  wallet={value}
                                  wallets={userWallets}
                                  {...field}
                                />
                              )}
                            />

                            <Grid container flexWrap="nowrap" columnGap={3}>
                              <Controller
                                name="stopLoss"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder="Enter amount"
                                    label="Stop Loss"
                                    tooltipInfo="Threshold of loss on token position, exceeding which tokens will be sold."
                                    adornmentText="%"
                                    {...field}
                                  />
                                )}
                              />

                              <Controller
                                name="stopLossAmount"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder="Enter amount"
                                    label="Stop Loss Amount"
                                    tooltipInfo="The percentage of tokens that will be sold when the stop loss is triggered."
                                    adornmentText="%"
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </HiddenInputs>
                  </Grid> */}
                </Grid>
              </Accordion>

              <Grid
                container
                gap={3}
                flexWrap="nowrap"
                flexDirection={isResponsive ? 'column' : 'row'}
              >
                <Controller
                  name="ordinaryBuy.privateTransaction"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <SwitchInline
                      label="Anti-Mev"
                      tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="ordinaryBuy.degenChadMode"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <SwitchInline
                      label="Degen Chad Mode"
                      tooltipInfo="For advanced traders. If enabled, the safety rules from the simulation will be ignored."
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Controller
                name="ordinaryBuy.slippage"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <PercentsInput
                    label="Slippage"
                    onOptionSelect={(value) => field.onChange(value)}
                    tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                    placeholder="X"
                    error={!!errors.ordinaryBuy?.slippage?.message}
                    {...field}
                  />
                )}
              />

              <Controller
                name="advancedBuy.buyPriority"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    className={styles.input}
                    label="Buy Priority"
                    placeholder="Enter gwei amount"
                    isNumeric
                    tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
                    endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                    error={!!errors.advancedBuy?.buyPriority?.message}
                    {...field}
                  />
                )}
              />

              {/* <Controller
                name="ordinaryBuy.buyPriority"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    className={styles.input}
                    label="Buy Priority"
                    placeholder="Enter gwei amount"
isNumeric
                    tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
                    endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                    {...field}
                  />
                )}
              /> */}

              <Controller
                name="ordinaryBuy.selectedWallets"
                control={control}
                render={({ field: { ref, value, onChange, ...field } }) => (
                  <SelectWallet
                    tooltipInfo="Select which wallets will be used to purchase token."
                    isMulti
                    wallet={value}
                    wallets={userWallets}
                    onChange={(value) => {
                      onChange(value)
                      setAvailableTokens(handleAvailableTokens())
                    }}
                    {...field}
                  />
                )}
              />
            </>
          )}

          {currentTemplate && (
            <BuyTemplateSettingsList
              template={currentTemplate as TBuyTemplate}
              expanded={isSettingsExpanded}
              onChange={(expanded) => {
                setIsSettingsExpanded(expanded)
                dispatch(clearCurrentSelectedTemplate())
              }}
            />
          )}

          {/* <Grid container rowGap={2} flexDirection="column">
            <ButtonGroupRadio
              onChange={handleFooterTabChange}
              value={footerTab}
              exclusive
              className={cn(styles.group, styles.bgGrey)}
            >
              <ButtonGroupRadioButton value="market">Market</ButtonGroupRadioButton>
              <ButtonGroupRadioButton value="limit">Limit</ButtonGroupRadioButton>
            </ButtonGroupRadio>
            {footerTab === 'limit' && (
              <Grid container rowGap={2} flexDirection="column" className={styles.limitBlock}>
                <div>
                  <Typography variant="body2" textColor="light-grey">
                    Trigger price
                  </Typography>
                  <div className={styles.inputWrapper}>
                    <Controller
                      name="ordinaryBuy.onLimit.dip"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={cn(styles.input, styles.first)}
                          placeholder="Enter dip %"
                          endAdornment={<EndAdornment label="%" />}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="ordinaryBuy.onLimit.marketcap"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={styles.input}
                          placeholder="Enter marketcap"
                          endAdornment={<EndAdornment label="MC" />}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="ordinaryBuy.onLimit.price"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={cn(styles.input, styles.last)}
                          placeholder="Enter price"
                          endAdornment={<EndAdornment label="$" />}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    name="ordinaryBuy.onLimit.triggerPricePercent"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <ButtonGroupRadio
                        className={styles.radioGroup}
                        onChange={(_, value: string) => onChange(value)}
                        exclusive
                        {...field}
                      >
                        {limitOptions.map((option) => (
                          <ButtonGroupRadioButton
                            value={option.value}
                            className={styles.radioButton}
                            key={option.value}
                          >
                            {option.label}
                          </ButtonGroupRadioButton>
                        ))}
                      </ButtonGroupRadio>
                    )}
                  />
                </div>

                <Controller
                  name="ordinaryBuy.onLimit.expiration"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      label="Expiration"
                      tooltipInfo='Enter the number of hours the limit order is valid. After this time expires, it will be removed.'
                      className={styles.input}
                      placeholder="Enter the position expiration time in hours"
                      adornmentText="Hours"
                      {...field}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid> */}
        </div>

        <div className={styles.footer}>
          <div className={styles.arrow}>
            <Icon name={IconName.ARROW_DOWN_GREY} />
          </div>

          <div className={styles.container}>
            <div className={styles.spend}>
              <Controller
                name="ordinaryBuy.spend"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => {
                  return (
                    <InputWithBuyAmount
                      label="Spend"
                      placeholder="Enter amount to spend"
                      isNumeric
                      className={styles.input}
                      onOptionSelect={(value) => {
                        const newValue = convertScientificNotationNumber(value, MAX_TRX_DECIMALS)
                        setLastUsedAmountInput(ELastUsedAmountInput.SPEND)
                        calculateReceive(newValue)
                      }}
                      endAdornment={
                        <EndAdornment
                          label={currentChain.description}
                          icon={(IconName as any)[currentChain.iconName]}
                          isDisabled={isDisabled}
                        />
                      }
                      radioGroupName="amount"
                      disabled={isDisabled}
                      defaultOptions={spendOptions}
                      onChange={(e) => {
                        if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                        const newValue = convertScientificNotationNumber(
                          e.target.value,
                          MAX_TRX_DECIMALS,
                          true,
                        )
                        setLastUsedAmountInput(ELastUsedAmountInput.SPEND)
                        calculateReceive(newValue)
                      }}
                      error={!!errors.ordinaryBuy?.spend?.message}
                      {...field}
                    />
                  )
                }}
              />
              <div className={styles.available}>
                <Typography variant="body2" className={styles.param}>
                  Available:
                </Typography>
                <Typography variant="body2" className={styles.value}>
                  {formatTokenPrice(convertScientificNotationNumber(availableTokens)).formatted} ETH
                </Typography>
              </div>
            </div>

            <div className={styles.union}>
              <Controller
                name="ordinaryBuy.receive"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Input
                    label="Receive"
                    placeholder="Enter amount to receive"
                    isNumeric
                    className={styles.input}
                    endAdornment={
                      <EndAdornment
                        label={currentToken?.token.info.symbol}
                        imageUrl={currentToken?.token.info.imageSmallUrl}
                        isDisabled={isDisabled}
                      />
                    }
                    disabled={isDisabled}
                    onChange={(e) => {
                      if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                      const newValue = convertScientificNotationNumber(
                        e.target.value,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      onChange(newValue)
                      setLastUsedAmountInput(ELastUsedAmountInput.RECEIVE)
                      calculateSpend(newValue)
                    }}
                    error={!!errors.ordinaryBuy?.receive?.message}
                    {...field}
                  />
                )}
              />
              <div className={cn(styles.infoContainer, { [styles.disabled]: isDisabled })}>
                <div className={styles.info}>
                  <Typography variant="body2" className={styles.param}>
                    DEX:
                  </Typography>
                  <Typography variant="body2" className={styles.value}>
                    {currentDex?.label}
                  </Typography>
                </div>
                <div className={styles.info}>
                  <Typography variant="body2" className={styles.param}>
                    Minimum received:
                  </Typography>
                  <Typography variant="body2" className={styles.value}>
                    {minimumReceive ? formatTokenPrice(minimumReceive.toString()).formatted : '-'}
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <Button type="submit" disabled={isDisabled} isLoading={isLoading}>
            Buy
          </Button>
        </div>
      </div>
    </form>
  )
}

const BuyTab: FC<TProps> = (props) => {
  const userWallets = useAppSelector((state) => state.user.userWallets)

  if (!userWallets?.length) {
    return (
      <Typography variant="body1" textColor="grey" align="center" marginBottom="12px">
        No wallets available
      </Typography>
    )
  }

  return <BuyTabForm {...props} />
}

export { BuyTab }
